import MainService from "@/services/MainService.js";

export default {
  name: "TipoReporteVerPage",
  data() {
    let ss = new MainService();
    return {
      msg: "TipoReporteVerPage",
      ss: ss,
      tipoReportes: {},
      reporte: {},
      personas: {},
      entidads: {},
      rutaApp: process.env.VUE_APP_MAIN_SERVICE,
      enable: {
        Entidad: false,
        Persona: false,
        Desde: false,
        Hasta: false,
      },
      errorBag: {},
    };
  },
  methods: {
    generaReportex(x, y) {
      var urlFile = this.rutaApp + "api/Generalx?x=" + x + "&y=" + y;
      // console.log(urlFile);
      if (y == 2) {
        this.ss
          .generaReporte(x, y)
          .then((result) => {
            console.log(result);
            this.isLoading = false;
            let response = result.data;
            if (response.success) {
              // this.setReset();

              this.$bvToast.toast(response.msg, {
                title: this.$t("messages.success"),
                variant: "success",
                autoHideDelay: 5000,
              });
              var a = document.createElement("a");
              a.href = response.data.url;
              a.target = "_blank";
              a.click();
            } else {
              this.$bvToast.toast(response.msg, {
                title: "Error",
                variant: "danger",
                autoHideDelay: 5000,
              });
            }
            this.errorBag = {};
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
            this.$bvToast.toast("Error al generar el reporte", {
              title: "Error",
              variant: "danger",
              autoHideDelay: 5000,
            });
            this.errorBag = error.data.errors;
          });
      } else {
        window.open(urlFile);
      }
    },
    verFormato() {
      this.getTipoReportes();
    },
    verParametros() {
      //console.log(event);
      //console.log(this.reporte);
      this.ss.showTipoReporte(this.reporte.TipoReporte).then((result) => {
        //console.log(result);

        var parametros = result.data.data.Parametros;
        console.log(parametros);
        if (parametros.includes(":identidad")) this.enable.Entidad = true;
        else this.enable.Entidad = false;

        if (parametros.includes(":idPersona")) this.enable.Persona = true;
        else this.enable.Persona = false;

        if (parametros.includes(":desde")) this.enable.Desde = true;
        else this.enable.Desde = false;

        if (parametros.includes(":hasta")) this.enable.Hasta = true;
        else this.enable.Hasta = false;
      });
    },
    setReset() {
      this.reporte = {};
    },
    getTipoReportes() {
      this.ss
        .listTipoReporte()
        .then((result) => (this.tipoReportes = result.data.data))
        .catch((error) => console.log(error));
    },
    getentidads() {
      this.ss
        .listEntidad()
        .then((result) => (this.entidads = result.data.data))
        .catch((error) => console.log(error));
    },
    getPersonas() {
      this.ss
        .listPersona()
        .then((result) => {
          let response = result.data;
          this.personas = response.data;
          //toastr.success('Usuarios Cargados', 'Correcto!');
        })
        .catch((error) => {
          console.log(error);
          //toastr.error('Error de Carga', 'Error!');
        });
    },
    generaReporte() {
      this.isLoading = true;
      //console.log(this.reporte);

      this.ss
        .generateTipoReporte(this.reporte)
        .then((result) => {
          console.log(result);
          this.isLoading = false;
          let response = result.data;
          if (response.success) {
            this.setReset();

            this.$bvToast.toast(response.msg, {
              title: this.$t("messages.success"),
              variant: "success",
              autoHideDelay: 5000,
            });
            var a = document.createElement("a");
            a.href = response.data.url;
            a.target = "_blank";
            a.click();
          } else {
            this.$bvToast.toast(response.msg, {
              title: "Error",
              variant: "danger",
              autoHideDelay: 5000,
            });
          }
          this.errorBag = {};
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
          this.$bvToast.toast("Error al generar el reporte", {
            title: "Error",
            variant: "danger",
            autoHideDelay: 5000,
          });
          this.errorBag = error.data.errors;
        });
    },
  },
  mounted() {
    var persona = JSON.parse(localStorage.getItem("persona"));
    if (!persona) {
      this.$router.push("/Admin/Login");
    } else {
      if (persona.Rol !== 1) {
        this.$router.push("/Admin/Login");
      }
      this.getentidads();
      this.getPersonas();
      this.getTipoReportes();
    }
  },
};
