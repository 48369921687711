export default class Constants {

    getListPantone() {
        return [
            "100 C",
            "101 C",
            "102 C",
            "103 C",
            "104 C",
            "105 C",
            "106 C",
            "107 C",
            "108 C",
            "109 C",
            "110 C",
            "111 C",
            "112 C",
            "113 C",
            "114 C",
            "115 C",
            "116 C",
            "117 C",
            "118 C",
            "119 C",
            "120 C",
            "1205 C",
            "121 C",
            "1215 C",
            "122 C",
            "1225 C",
            "123 C",
            "1235 C",
            "124 C",
            "1245 C",
            "125 C",
            "1255 C",
            "126 C",
            "1265 C",
            "127 C",
            "128 C",
            "129 C",
            "130 C",
            "131 C",
            "132 C",
            "133 C",
            "134 C",
            "1345 C",
            "135 C",
            "1355 C",
            "136 C",
            "1365 C",
            "137 C",
            "1375 C",
            "138 C",
            "1385 C",
            "139 C",
            "1395 C",
            "140 C",
            "1405 C",
            "141 C",
            "142 C",
            "143 C",
            "144 C",
            "145 C",
            "146 C",
            "147 C",
            "148 C",
            "1485 C",
            "149 C",
            "1495 C",
            "150 C",
            "1505 C",
            "151 C",
            "152 C",
            "1525 C",
            "153 C",
            "1535 C",
            "154 C",
            "1545 C",
            "155 C",
            "1555 C",
            "156 C",
            "1565 C",
            "157 C",
            "1575 C",
            "158 C",
            "1585 C",
            "159 C",
            "1595 C",
            "160 C",
            "1605 C",
            "161 C",
            "1615 C",
            "162 C",
            "1625 C",
            "163 C",
            "1635 C",
            "164 C",
            "1645 C",
            "165 C",
            "1655 C",
            "166 C",
            "1665 C",
            "167 C",
            "1675 C",
            "168 C",
            "1685 C",
            "169 C",
            "170 C",
            "171 C",
            "172 C",
            "173 C",
            "174 C",
            "175 C",
            "176 C",
            "1765 C",
            "1767 C",
            "177 C",
            "1775 C",
            "1777 C",
            "178 C",
            "1785 C",
            "1787 C",
            "1788 C",
            "179 C",
            "1795 C",
            "1797 C",
            "180 C",
            "1805 C",
            "1807 C",
            "181 C",
            "1815 C",
            "1817 C",
            "182 C",
            "183 C",
            "184 C",
            "185 C",
            "186 C",
            "187 C",
            "188 C",
            "189 C",
            "1895 C",
            "190 C",
            "1905 C",
            "191 C",
            "1915 C",
            "192 C",
            "1925 C",
            "193 C",
            "1935 C",
            "194 C",
            "1945 C",
            "195 C",
            "1955 C",
            "196 C",
            "197 C",
            "198 C",
            "199 C",
            "200 C",
            "2002 C",
            "201 C",
            "202 C",
            "203 C",
            "204 C",
            "205 C",
            "206 C",
            "207 C",
            "208 C",
            "209 C",
            "210 C",
            "211 C",
            "212 C",
            "2129 C",
            "213 C",
            "214 C",
            "2143 C",
            "215 C",
            "2152 C",
            "216 C",
            "217 C",
            "218 C",
            "219 C",
            "220 C",
            "221 C",
            "222 C",
            "223 C",
            "224 C",
            "225 C",
            "226 C",
            "227 C",
            "228 C",
            "229 C",
            "2299 C",
            "230 C",
            "231 C",
            "232 C",
            "233 C",
            "234 C",
            "235 C",
            "236 C",
            "2365 C",
            "237 C",
            "2375 C",
            "238 C",
            "2385 C",
            "239 C",
            "2395 C",
            "240 C",
            "2405 C",
            "241 C",
            "2415 C",
            "242 C",
            "2425 C",
            "243 C",
            "244 C",
            "245 C",
            "246 C",
            "247 C",
            "248 C",
            "249 C",
            "250 C",
            "251 C",
            "252 C",
            "253 C",
            "254 C",
            "255 C",
            "256 C",
            "2562 C",
            "2563 C",
            "2567 C",
            "257 C",
            "2572 C",
            "2573 C",
            "2577 C",
            "258 C",
            "2582 C",
            "2583 C",
            "2587 C",
            "259 C",
            "2592 C",
            "2593 C",
            "2597 C",
            "260 C",
            "2602 C",
            "2603 C",
            "2062 C",
            "2607 C",
            "261 C",
            "2612 C",
            "2613 C",
            "2617 C",
            "262 C",
            "2622 C",
            "2623 C",
            "2627 C",
            "263 C",
            "2635 C",
            "264 C",
            "2645 C",
            "265 C",
            "2655 C",
            "266 C",
            "2665 C",
            "267 C",
            "268 C",
            "2685 C",
            "269 C",
            "2695 C",
            "270 C",
            "2705 C",
            "2706 C",
            "2707 C",
            "2708 C",
            "271 C",
            "2715 C",
            "2716 C",
            "2717 C",
            "2718 C",
            "272 C",
            "2725 C",
            "2726 C",
            "2727 C",
            "2728 C",
            "273 C",
            "2735 C",
            "2736 C",
            "2738 C",
            "274 C",
            "2745 C",
            "2746 C",
            "2747 C",
            "2748 C",
            "275 C",
            "2755 C",
            "2756 C",
            "2757 C",
            "2758 C",
            "276 C",
            "2765 C",
            "2766 C",
            "2767 C",
            "2768 C",
            "277 C",
            "278 C",
            "279 C",
            "280 C",
            "281 C",
            "282 C",
            "283 C",
            "284 C",
            "285 C",
            "286 C",
            "287 C",
            "288 C",
            "289 C",
            "290 C",
            "2905 C",
            "291 C",
            "2915 C",
            "292 C",
            "2925 C",
            "293 C",
            "2935 C",
            "294 C",
            "2945 C",
            "295 C",
            "2955 C",
            "296 C",
            "2965 C",
            "297 C",
            "2975 C",
            "298 C",
            "2985 C",
            "299 C",
            "2995 C",
            "300 C",
            "3005 C",
            "301 C",
            "3015 C",
            "302 C",
            "3025 C",
            "303 C",
            "3035 C",
            "304 C",
            "305 C",
            "306 C",
            "307 C",
            "308 C",
            "309 C",
            "310 C",
            "3105 C",
            "311 C",
            "3115 C",
            "312 C",
            "3125 C",
            "313 C",
            "3135 C",
            "314 C",
            "3145 C",
            "315 C",
            "3155 C",
            "316 C",
            "3165 C",
            "317 C",
            "318 C",
            "319 C",
            "320 C",
            "321 C",
            "322 C",
            "323 C",
            "324 C",
            "3242 C",
            "3245 C",
            "3248 C",
            "325 C",
            "3252 C",
            "3255 C",
            "3258 C",
            "326 C",
            "3262 C",
            "3265 C",
            "3268 C",
            "327 C",
            "3272 C",
            "3275 C",
            "3278 C",
            "328 C",
            "3282 C",
            "3285 C",
            "3288 C",
            "329 C",
            "3292 C",
            "3295 C",
            "3298 C",
            "330 C",
            "3302 C",
            "3305 C",
            "3308 C",
            "331 C",
            "332 C",
            "333 C",
            "334 C",
            "335 C",
            "336 C",
            "337 C",
            "3375 C",
            "338 C",
            "3385 C",
            "339 C",
            "3395 C",
            "340 C",
            "3405 C",
            "341 C",
            "3415 C",
            "342 C",
            "3425 C",
            "343 C",
            "3435 C",
            "344 C",
            "345 C",
            "346 C",
            "347 C",
            "348 C",
            "349 C",
            "350 C",
            "351 C",
            "352 C",
            "353 C",
            "354 C",
            "355 C",
            "356 C",
            "357 C",
            "358 C",
            "359 C",
            "360 C",
            "361 C",
            "362 C",
            "363 C",
            "364 C",
            "365 C",
            "366 C",
            "367 C",
            "368 C",
            "369 C",
            "370 C",
            "371 C",
            "372 C",
            "373 C",
            "374 C",
            "375 C",
            "376 C",
            "377 C",
            "378 C",
            "379 C",
            "380 C",
            "381 C",
            "382 C",
            "383 C",
            "384 C",
            "385 C",
            "386 C",
            "387 C",
            "388 C",
            "389 C",
            "390 C",
            "391 C",
            "392 C",
            "393 C",
            "3935 C",
            "394 C",
            "3945 C",
            "395 C",
            "3955 C",
            "396 C",
            "3965 C",
            "397 C",
            "3975 C",
            "398 C",
            "3985 C",
            "399 C",
            "3995 C",
            "400 C",
            "401 C",
            "402 C",
            "403 C",
            "404 C",
            "405 C",
            "406 C",
            "407 C",
            "408 C",
            "409 C",
            "410 C",
            "411 C",
            "412 C",
            "413 C",
            "414 C",
            "415 C",
            "416 C",
            "417 C",
            "418 C",
            "419 C",
            "420 C",
            "421 C",
            "422 C",
            "423 C",
            "424 C",
            "425 C",
            "426 C",
            "427 C",
            "428 C",
            "429 C",
            "430 C",
            "431 C",
            "432 C",
            "433 C",
            "434 C",
            "435 C",
            "436 C",
            "437 C",
            "438 C",
            "439 C",
            "440 C",
            "441 C",
            "442 C",
            "443 C",
            "444 C",
            "445 C",
            "446 C",
            "447 C",
            "448 C",
            "4485 C",
            "449 C",
            "4495 C",
            "450 C",
            "4505 C",
            "451 C",
            "4515 C",
            "452 C",
            "4525 C",
            "453 C",
            "4535 C",
            "454 C",
            "4545 C",
            "455 C",
            "456 C",
            "457 C",
            "458 C",
            "459 C",
            "460 C",
            "461 C",
            "462 C",
            "4625 C",
            "463 C",
            "4635 C",
            "464 C",
            "4645 C",
            "465 C",
            "4655 C",
            "466 C",
            "4665 C",
            "467 C",
            "4675 C",
            "468 C",
            "4685 C",
            "469 C",
            "4695 C",
            "470 C",
            "4705 C",
            "471 C",
            "4715 C",
            "472 C",
            "4725 C",
            "473 C",
            "4735 C",
            "474 C",
            "4745 C",
            "475 C",
            "4755 C",
            "476 C",
            "477 C",
            "478 C",
            "479 C",
            "480 C",
            "481 C",
            "482 C",
            "483 C",
            "484 C",
            "485 C",
            "486 C",
            "487 C",
            "488 C",
            "489 C",
            "490 C",
            "491 C",
            "492 C",
            "493 C",
            "494 C",
            "495 C",
            "496 C",
            "497 C",
            "4975 C",
            "498 C",
            "4985 C",
            "499 C",
            "4995 C",
            "500 C",
            "5005 C",
            "501 C",
            "5015 C",
            "502 C",
            "5025 C",
            "503 C",
            "5035 C",
            "504 C",
            "505 C",
            "506 C",
            "507 C",
            "508 C",
            "509 C",
            "510 C",
            "511 C",
            "5115 C",
            "512 C",
            "5125 C",
            "513 C",
            "5135 C",
            "514 C",
            "5145 C",
            "515 C",
            "5155 C",
            "516 C",
            "5165 C",
            "517 C",
            "5175 C",
            "518 C",
            "5185 C",
            "519 C",
            "5195 C",
            "520 C",
            "5205 C",
            "521 C",
            "5215 C",
            "522 C",
            "5225 C",
            "523 C",
            "5235 C",
            "524 C",
            "5245 C",
            "525 C",
            "5255 C",
            "526 C",
            "5265 C",
            "527 C",
            "5275 C",
            "528 C",
            "5285 C",
            "529 C",
            "5295 C",
            "530 C",
            "5305 C",
            "531 C",
            "5315 C",
            "532 C",
            "533 C",
            "534 C",
            "535 C",
            "536 C",
            "537 C",
            "538 C",
            "539 C",
            "5395 C",
            "540 C",
            "5405 C",
            "541 C",
            "5415 C",
            "542 C",
            "5425 C",
            "543 C",
            "5435 C",
            "544 C",
            "5445 C",
            "545 C",
            "5455 C",
            "546 C",
            "5463 C",
            "5467 C",
            "547 C",
            "5473 C",
            "5477 C",
            "548 C",
            "5483 C",
            "5487 C",
            "549 C",
            "5493 C",
            "5497 C",
            "550 C",
            "5503 C",
            "5507 C",
            "551 C",
            "5513 C",
            "5517 C",
            "552 C",
            "5523 C",
            "5527 C",
            "553 C",
            "5535 C",
            "554 C",
            "5545 C",
            "555 C",
            "5555 C",
            "556 C",
            "5565 C",
            "557 C",
            "5575 C",
            "558 C",
            "5585 C",
            "559 C",
            "5595 C",
            "560 C",
            "5605 C",
            "561 C",
            "5615 C",
            "562 C",
            "5625 C",
            "563 C",
            "5635 C",
            "564 C",
            "5645 C",
            "565 C",
            "5655 C",
            "566 C",
            "5665 C",
            "567 C",
            "568 C",
            "569 C",
            "570 C",
            "571 C",
            "572 C",
            "573 C",
            "574 C",
            "5743 C",
            "5747 C",
            "575 C",
            "5753 C",
            "5757 C",
            "576 C",
            "5763 C",
            "5767 C",
            "577 C",
            "5773 C",
            "5777 C",
            "578 C",
            "5783 C",
            "5787 C",
            "579 C",
            "5793 C",
            "5797 C",
            "580 C",
            "5803 C",
            "5807 C",
            "581 C",
            "5815 C",
            "582 C",
            "5825 C",
            "583 C",
            "5835 C",
            "584 C",
            "5845 C",
            "585 C",
            "5855 C",
            "586 C",
            "5865 C",
            "587 C",
            "5875 C",
            "600 C",
            "601 C",
            "602 C",
            "603 C",
            "604 C",
            "605 C",
            "606 C",
            "607 C",
            "608 C",
            "609 C",
            "610 C",
            "611 C",
            "612 C",
            "613 C",
            "614 C",
            "615 C",
            "616 C",
            "617 C",
            "618 C",
            "619 C",
            "620 C",
            "621 C",
            "622 C",
            "623 C",
            "624 C",
            "625 C",
            "626 C",
            "627 C",
            "628 C",
            "629 C",
            "630 C",
            "631 C",
            "632 C",
            "633 C",
            "634 C",
            "635 C",
            "636 C",
            "637 C",
            "638 C",
            "639 C",
            "640 C",
            "641 C",
            "642 C",
            "643 C",
            "644 C",
            "645 C",
            "646 C",
            "647 C",
            "648 C",
            "649 C",
            "650 C",
            "651 C",
            "652 C",
            "653 C",
            "654 C",
            "655 C",
            "656 C",
            "657 C",
            "658 C",
            "659 C",
            "660 C",
            "661 C",
            "662 C",
            "663 C",
            "664 C",
            "665 C",
            "666 C",
            "667 C",
            "668 C",
            "669 C",
            "670 C",
            "671 C",
            "672 C",
            "673 C",
            "674 C",
            "675 C",
            "676 C",
            "677 C",
            "678 C",
            "679 C",
            "680 C",
            "681 C",
            "682 C",
            "683 C",
            "684 C",
            "685 C",
            "686 C",
            "687 C",
            "688 C",
            "689 C",
            "690 C",
            "691 C",
            "692 C",
            "693 C",
            "694 C",
            "695 C",
            "696 C",
            "697 C",
            "698 C",
            "699 C",
            "700 C",
            "701 C",
            "702 C",
            "703 C",
            "704 C",
            "705 C",
            "706 C",
            "707 C",
            "708 C",
            "709 C",
            "710 C",
            "711 C",
            "712 C",
            "713 C",
            "714 C",
            "715 C",
            "716 C",
            "717 C",
            "718 C",
            "719 C",
            "720 C",
            "721 C",
            "722 C",
            "723 C",
            "724 C",
            "725 C",
            "726 C",
            "727 C",
            "728 C",
            "729 C",
            "730 C",
            "731 C",
            "732 C",
            "7401 C",
            "7402 C",
            "7403 C",
            "7404 C",
            "7405 C",
            "7406 C",
            "7407 C",
            "7408 C",
            "7409 C",
            "7410 C",
            "7411 C",
            "7412 C",
            "7413 C",
            "7414 C",
            "7415 C",
            "7416 C",
            "7417 C",
            "7418 C",
            "7419 C",
            "7420 C",
            "7421 C",
            "7422 C",
            "7423 C",
            "7424 C",
            "7425 C",
            "7426 C",
            "7427 C",
            "7428 C",
            "7429 C",
            "7430 C",
            "7431 C",
            "7432 C",
            "7433 C",
            "7434 C",
            "7435 C",
            "7436 C",
            "7437 C",
            "7438 C",
            "7439 C",
            "7440 C",
            "7441 C",
            "7442 C",
            "7443 C",
            "7444 C",
            "7445 C",
            "7446 C",
            "7447 C",
            "7448 C",
            "7449 C",
            "7450 C",
            "7451 C",
            "7452 C",
            "7453 C",
            "7454 C",
            "7455 C",
            "7456 C",
            "7457 C",
            "7458 C",
            "7459 C",
            "7460 C",
            "7461 C",
            "7462 C",
            "7463 C",
            "7464 C",
            "7465 C",
            "7466 C",
            "7467 C",
            "7468 C",
            "7469 C",
            "7470 C",
            "7471 C",
            "7472 C",
            "7473 C",
            "7474 C",
            "7475 C",
            "7476 C",
            "7477 C",
            "7478 C",
            "7479 C",
            "7480 C",
            "7481 C",
            "7482 C",
            "7483 C",
            "7484 C",
            "7485 C",
            "7486 C",
            "7487 C",
            "7488 C",
            "7489 C",
            "7490 C",
            "7491 C",
            "7492 C",
            "7493 C",
            "7494 C",
            "7495 C",
            "7496 C",
            "7497 C",
            "7498 C",
            "7499 C",
            "7500 C",
            "7501 C",
            "7502 C",
            "7503 C",
            "7504 C",
            "7505 C",
            "7506 C",
            "7507 C",
            "7508 C",
            "7509 C",
            "7510 C",
            "7511 C",
            "7512 C",
            "7513 C",
            "7514 C",
            "7515 C",
            "7516 C",
            "7517 C",
            "7518 C",
            "7519 C",
            "7520 C",
            "7521 C",
            "7522 C",
            "7523 C",
            "7524 C",
            "7525 C",
            "7526 C",
            "7527 C",
            "7528 C",
            "7529 C",
            "7530 C",
            "7531 C",
            "7532 C",
            "7533 C",
            "7534 C",
            "7535 C",
            "7536 C",
            "7537 C",
            "7538 C",
            "7539 C",
            "7540 C",
            "7541 C",
            "7542 C",
            "7543 C",
            "7544 C",
            "7545 C",
            "7546 C",
            "7547 C",
            "7548 C",
            "7549 C",
            "7550 C",
            "7551 C",
            "7552 C",
            "7553 C",
            "7554 C",
            "7555 C",
            "7556 C",
            "7557 C",
            "7558 C",
            "7559 C",
            "7560 C",
            "7561 C",
            "7562 C",
            "7563 C",
            "7564 C",
            "7565 C",
            "7566 C",
            "7567 C",
            "7568 C",
            "7569 C",
            "7570 C",
            "7571 C",
            "7572 C",
            "7573 C",
            "7574 C",
            "7575 C",
            "7576 C",
            "7577 C",
            "7578 C",
            "7579 C",
            "7580 C",
            "7581 C",
            "7582 C",
            "7583 C",
            "7584 C",
            "7585 C",
            "7586 C",
            "7587 C",
            "7588 C",
            "7589 C",
            "7590 C",
            "7591 C",
            "7592 C",
            "7593 C",
            "7594 C",
            "7595 C",
            "7596 C",
            "7597 C",
            "7598 C",
            "7599 C",
            "7600 C",
            "7601 C",
            "7602 C",
            "7603 C",
            "7604 C",
            "7605 C",
            "7606 C",
            "7607 C",
            "7608 C",
            "7609 C",
            "7610 C",
            "7611 C",
            "7612 C",
            "7613 C",
            "7614 C",
            "7615 C",
            "7616 C",
            "7617 C",
            "7618 C",
            "7619 C",
            "7620 C",
            "7621 C",
            "7622 C",
            "7623 C",
            "7624 C",
            "7625 C",
            "7626 C",
            "7627 C",
            "7628 C",
            "7629 C",
            "7630 C",
            "7631 C",
            "7632 C",
            "7633 C",
            "7634 C",
            "7635 C",
            "7636 C",
            "7637 C",
            "7638 C",
            "7639 C",
            "7640 C",
            "7641 C",
            "7642 C",
            "7643 C",
            "7644 C",
            "7645 C",
            "7646 C",
            "7647 C",
            "7648 C",
            "7649 C",
            "7650 C",
            "7651 C",
            "7652 C",
            "7653 C",
            "7654 C",
            "7655 C",
            "7656 C",
            "7657 C",
            "7658 C",
            "7659 C",
            "7660 C",
            "7661 C",
            "7662 C",
            "7663 C",
            "7664 C",
            "7665 C",
            "7666 C",
            "7667 C",
            "7668 C",
            "7669 C",
            "7670 C",
            "7671 C",
            "7672 C",
            "7673 C",
            "7674 C",
            "7675 C",
            "7676 C",
            "7677 C",
            "7678 C",
            "7679 C",
            "7680 C",
            "7681 C",
            "7682 C",
            "7683 C",
            "7684 C",
            "7685 C",
            "7686 C",
            "7687 C",
            "7688 C",
            "7689 C",
            "7690 C",
            "7691 C",
            "7692 C",
            "7693 C",
            "7694 C",
            "7695 C",
            "7696 C",
            "7697 C",
            "7698 C",
            "7699 C",
            "7700 C",
            "7701 C",
            "7702 C",
            "7703 C",
            "7704 C",
            "7705 C",
            "7706 C",
            "7707 C",
            "7708 C",
            "7709 C",
            "7710 C",
            "7711 C",
            "7712 C",
            "7713 C",
            "7714 C",
            "7715 C",
            "7716 C",
            "7717 C",
            "7718 C",
            "7719 C",
            "7720 C",
            "7721 C",
            "7722 C",
            "7723 C",
            "7724 C",
            "7725 C",
            "7726 C",
            "7727 C",
            "7728 C",
            "7729 C",
            "7730 C",
            "7731 C",
            "7732 C",
            "7733 C",
            "7734 C",
            "7735 C",
            "7736 C",
            "7737 C",
            "7738 C",
            "7739 C",
            "7740 C",
            "7741 C",
            "7742 C",
            "7743 C",
            "7744 C",
            "7745 C",
            "7746 C",
            "7747 C",
            "7748 C",
            "7749 C",
            "7750 C",
            "7751 C",
            "7752 C",
            "7753 C",
            "7754 C",
            "7755 C",
            "7756 C",
            "7757 C",
            "7758 C",
            "7759 C",
            "7760 C",
            "7761 C",
            "7762 C",
            "7763 C",
            "7764 C",
            "7765 C",
            "7766 C",
            "7767 C",
            "7768 C",
            "7769 C",
            "7770 C",
            "7771 C",
            "801 C",
            "802 C",
            "803 C",
            "804 C",
            "805 C",
            "806 C",
            "807 C",
            "808 C",
            "809 C",
            "810 C",
            "811 C",
            "812 C",
            "813 C",
            "814 C",
            "BLACK 2 C",
            "BLACK 3 C",
            "BLACK 4 C",
            "BLACK 5 C",
            "BLACK 6 C",
            "BLACK 7 C",
            "BLACK C",
            "BLUE 072 C",
            "COOL GRAY 1 C",
            "COOL GRAY 10 C",
            "COOL GRAY 11 C",
            "COOL GRAY 2 C",
            "COOL GRAY 3 C",
            "COOL GRAY 4 C",
            "COOL GRAY 5 C",
            "COOL GRAY 6 C",
            "COOL GRAY 7 C",
            "COOL GRAY 8 C",
            "COOL GRAY 9 C",
            "GREEN C",
            "HEXACHROME BLACK C",
            "HEXACHROME CYAN C",
            "HEXACHROME GREEN C",
            "HEXACHROME MAGENTA C",
            "HEXACHROME ORANGE C",
            "HEXACHROME YELLOW C",
            "ORANGE 021 C",
            "PROCESS BLUE C",
            "PURPLE C",
            "RED 032 C",
            "REFLEX BLUE C",
            "RHODAMINE RED C",
            "RUBINE RED C",
            "VIOLET C",
            "WARM GRAY 1 C",
            "WARM GRAY 10 C",
            "WARM GRAY 11 C",
            "WARM GRAY 2 C",
            "WARM GRAY 3 C",
            "WARM GRAY 4 C",
            "WARM GRAY 5 C",
            "WARM GRAY 6 C",
            "WARM GRAY 7 C",
            "WARM GRAY 8 C",
            "WARM GRAY 9 C",
            "WARM RED C",
            "YELLOW 012 C",
            "YELLOW C",
            "939 C",
            "2368 C"
        ];
    }

    getMes() {
        return [
            'Enero',
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Septiembre',
            'Octubre',
            'Noviembre',
            'Diciembre'
        ];
    }

    getListMagnum() {
        return [{
                magnum: "DPT MAGNUM MIXING BASE",
                color: "246,248,223",
                id: 1,
                hex: "#F6F8DF"
            },
            {
                magnum: "MMS CC BLACK 500",
                color: "29,28,26",
                id: 2,
                hex: "#1D1C1A"
            },
            {
                magnum: "MMS CC BLUE G 500",
                color: "13,78,150",
                id: 3,
                hex: "#0D4E96"
            },
            {
                magnum: "MMS CC BLUE R 500",
                color: "53,55,120",
                id: 4,
                hex: "#353778"
            },
            {
                magnum: "MMS CC BLUE UM 500",
                color: "53,62,151",
                id: 5,
                hex: "#353E97"
            },
            {
                magnum: "MMS CC FLUO FUCHSIA 500",
                color: "255,5,113",
                id: 6,
                hex: "#FF0571"
            },
            {
                magnum: "MMS CC FLUO ORANGE 500",
                color: "255,163,0",
                id: 7,
                hex: "#FFA300"
            },
            {
                magnum: "MMS CC FLUO YELLOW 500",
                color: "237,255,0",
                id: 8,
                hex: "#EDFF00"
            },
            {
                magnum: "MMS CC GREEN 500",
                color: "0,149,95",
                id: 9,
                hex: "#00955F"
            },
            {
                magnum: "MMS CC LEMON YELLOW QM 500",
                color: "250,236,34",
                id: 10,
                hex: "#FAEC22"
            },
            {
                magnum: "MMS CC MAGENTA QM 500",
                color: "170,27,119",
                id: 11,
                hex: "#AA1B77"
            },
            {
                magnum: "MMS CC RED QM 500",
                color: "213,31,52",
                id: 12,
                hex: "#D51F34"
            },
            {
                magnum: "MMS CC VIOLET 500",
                color: "143,0,255",
                id: 13,
                hex: "#8F00FF"
            },
            {
                magnum: "MMS CC WHITE 500",
                color: "255 255 255",
                id: 14,
                hex: "#FFFFFF"
            },
            {
                magnum: "MMS CC YELLOW 500",
                color: "246,184,0",
                id: 15,
                hex: "#F6B800"
            },
        ];
    }
}