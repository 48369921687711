import MainService from "@/services/MainService.js";
import Vue from "vue";
import VueAxios from "vue-axios";
import axios from "axios";
import MathMethods from "../../../utils/MathMethods";
import StorageMethods from "@/utils/StorageMethods.js";

import PageOptions from "../../../config/PageOptions.vue";

Vue.use(VueAxios, axios);
// Vue.use( axios);

export default {
  name: "LoginPage",
  data() {
    let math = new MathMethods();
    let ss = new MainService();
    let storage = new StorageMethods();
    return {
      ss: ss,
      loginRequest: {},
      errorBag: {},
      hash: "",
      size: 300,
      alias: "",
      storage: storage,
      FB: {},
      model: {},
      unlock: false,
      swiperOption: {
        notNextTick: false,
        loop: true,
        initialSlide: 0,
        effect: "slide",
        autoplay: {
          delay: 1500,
        },
        speed: 800,
        grabCursor: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          type: "bullets",
        },
      },
      img: [
        "/assets/img/fondos/login.png",
        "/assets/img/fondos/4.png",
        "/assets/img/fondos/register.png",
        "/assets/img/fondos/5.png",
      ],
      imagen: 0,
      scope: {},
      channel: "",
      name: process.env.VUE_APP_TITLE,
      bg: {
        activeImg: "/assets/img/login-bg/login-bg-17.jpg",
        bg1: {
          active: true,
          img: "/assets/img/login-bg/login-bg-17.jpg",
        },
        bg2: {
          active: false,
          img: "/assets/img/login-bg/login-bg-16.jpg",
        },
        bg3: {
          active: false,
          img: "/assets/img/login-bg/login-bg-15.jpg",
        },
        bg4: {
          active: false,
          img: "/assets/img/login-bg/login-bg-14.jpg",
        },
        bg5: {
          active: false,
          img: "/assets/img/login-bg/login-bg-13.jpg",
        },
        bg6: {
          active: false,
          img: "/assets/img/login-bg/login-bg-12.jpg",
        },
      },
    };
  },
  props: {
    msg: String,
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      //console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
    };
  },
  created() {
    PageOptions.pageEmpty = true;
  },
  beforeRouteLeave(to, from, next) {
    PageOptions.pageEmpty = false;
    PageOptions.pageWithFooter = true;
    next();
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  methods: {
    async googleLogin() {
      //   const googleUser = await this.$gAuth.signIn();
      //   let googleProfile = googleUser.getBasicProfile();
      //   let user = {};
      //   if (googleProfile) {
      //     user = {
      //       Nombres: googleProfile.getName(),
      //       email: googleProfile.getEmail(),
      //       Foto: googleProfile.getImageUrl(),
      //       Entidad: 2,
      //       Rol: 3,
      //       SocialLogin: "g",
      //     };
      //   }
      //   if (user) {
      //     this.ss
      //       .socialLogin(user)
      //       .then((result) => {
      //         localStorage.setItem("persona", JSON.stringify(result.data.data));
      //         localStorage.setItem("token", JSON.stringify(result.data.token));
      //         localStorage.setItem("admin", false);
      //         this.$router.push("/");
      //         this.$router.go();
      //         /*var persona = JSON.parse(localStorage.getItem('persona'));
      //                                   console.log(persona)*/
      //       })
      //       .catch((error) => {
      //         this.errorBag = error.response.data.errors;
      //         this.$bvToast.toast("Problema al Iniciar Sesion", {
      //           title: "Error",
      //           variant: "danger",
      //           autoHideDelay: 5000,
      //         });
      //       });
      //   }
    },
    async facebookLogin() {
      //this.$facebookAuth().load();
    },
    unlockPassword() {
      this.unlock = !this.unlock;
      var x = document.getElementById("passwordLoginUser");
      if (x.type === "password") {
        x.type = "text";
      } else {
        x.type = "password";
      }
    },
    onSwiper(swiper) {
      console.log(swiper);
    },
    onSlideChange() {
      console.log("slide change");
    },
    login() {
      // if (!this.loginRequest.email && !this.loginRequest.password) {
      //   console.log("data ", this.loginRequest);
      //   if (!this.loginRequest.email) {
      //     this.errorBag.email = this.$t("messages.emailRequired");
      //   }
      //   if (!this.loginRequest.password) {
      //     this.errorBag.password = this.$t("messages.passwordRequired");
      //   }
      //   this.$forceUpdate();
      // } else {
      this.ss
        .login(this.loginRequest)
        .then((result) => {
          localStorage.setItem("persona", JSON.stringify(result.data.data));
          localStorage.setItem("token", JSON.stringify(result.data.token));
          localStorage.setItem("admin", false);
          this.$router.push("/");
        })
        .catch((error) => {
          this.$bvToast.toast(this.$t("messages.userNotFoundMessage"), {
            title: "Error",
            variant: "danger",
            autoHideDelay: 5000,
          });
        });
      // }
    },
    checkForm: function (e) {
      e.preventDefault();
      this.login();
    },
    isEmpty(data) {
      return !data || data === "";
    },
    select: function (x) {
      this.bg.bg1.active = false;
      this.bg.bg2.active = false;
      this.bg.bg3.active = false;
      this.bg.bg4.active = false;
      this.bg.bg5.active = false;
      this.bg.bg6.active = false;

      switch (x) {
        case "bg1":
          this.bg.bg1.active = true;
          this.bg.activeImg = this.bg.bg1.img;
          break;
        case "bg2":
          this.bg.bg2.active = true;
          this.bg.activeImg = this.bg.bg2.img;
          break;
        case "bg3":
          this.bg.bg3.active = true;
          this.bg.activeImg = this.bg.bg3.img;
          break;
        case "bg4":
          this.bg.bg4.active = true;
          this.bg.activeImg = this.bg.bg4.img;
          break;
        case "bg5":
          this.bg.bg5.active = true;
          this.bg.activeImg = this.bg.bg5.img;
          break;
        case "bg6":
          this.bg.bg6.active = true;
          this.bg.activeImg = this.bg.bg6.img;
          break;
      }
    },
  },
  mounted() {
    this.imagen = Math.floor(Math.random() * 3);
    if (
      process.env.VUE_APP_ENVIRONMENT === "PRODUCTION" &&
      location.protocol !== "https:"
    ) {
      location.replace(
        `https:${location.href.substring(location.protocol.length)}`
      );
    }
    const { code, n, lang } = this.$route.query;
    if (code) {
      localStorage.setItem("token", JSON.stringify(code));
      this.ss
        .showPersona(n)
        .then((result) => {
          localStorage.setItem("persona", JSON.stringify(result.data.data));
          localStorage.setItem("admin", false);
          this.$router.push("/");
        })
        .catch((error) => {
          this.errorBag = error.response.data.errors;
          //   this.$bvToast.toast("Problema al Iniciar Sesion", {
          //     title: "Error",
          //     variant: "danger",
          //     autoHideDelay: 5000,
          //   });
        });
    }
    var persona = JSON.parse(localStorage.getItem("persona"));
    if (persona) {
      this.storage.cleanSession();
    }
  },
};
