import MainService from "@/services/MainService.js";
import PageOptions from "../../../config/PageOptions.vue";

export default {
  name: "RegisterPage",
  data() {
    let ss = new MainService();
    return {
      msg: "RegisterPage",
      ss: ss,
      errorBag: {},
      img: [
        "/assets/img/fondos/login.png",
        "/assets/img/fondos/4.png",
        "/assets/img/fondos/register.png",
        "/assets/img/fondos/5.png",
      ],
      imagen: 0,
      ajax: {
        url: ss.indexPersona(),
        headers: ss.getToken(),
      },
      persona: {},
      unlock: false,
    };
  },
  created() {
    PageOptions.pageEmpty = true;
    document.body.className = "bg-white";
  },
  beforeRouteLeave(to, from, next) {
    PageOptions.pageEmpty = false;
    document.body.className = "";
    next();
  },
  methods: {
    unlockPassword() {
      this.unlock = !this.unlock;
      var x = document.getElementById("passwordRegister");
      if (x.type === "password") {
        x.type = "text";
      } else {
        x.type = "password";
      }
      var y = document.getElementById("passwordConfirmRegister");
      if (y.type === "password") {
        y.type = "text";
      } else {
        y.type = "password";
      }
    },
    checkForm: function (e) {
      e.preventDefault();
      this.registerPerson();
    },
    isEmpty(data) {
      return !data || data === "";
    },
    registerPerson() {
      if (this.persona.password !== this.persona.passwordConfirm) {
        this.$bvToast.toast(this.$t("messages.passwordNotEquals"), {
          title: "Error",
          variant: "danger",
          autoHideDelay: 5000,
        });
      } else if (this.persona.email !== this.persona.confirmEmail) {
        this.$bvToast.toast(this.$t("messages.emailNotEquals"), {
          title: "Error",
          variant: "danger",
          autoHideDelay: 5000,
        });
      } else {
        this.ss
          .storeExterno(this.persona)
          .then((result) => {
            let response = result.data;
            if (response.success) {
              this.$swal({
                title: this.$t("messages.verifyEmail"),
                text: this.$t("messages.verifyEmailMessage"),
                icon: "success",
                allowOutsideClick: false,
                confirmButtonText: this.$t("messages.goLogin"),
              }).then((willDelete) => {
                if (willDelete.isConfirmed) {
                  this.$router.push("/Login");
                }
              });
            } else {
              this.$bvToast.toast(this.$t("messages.userRegisterExist"), {
                title: this.$t("messages.errorDefault"),
                variant: "danger",
                autoHideDelay: 5000,
              });
            }
          })
          .catch((error) => {
            this.$bvToast.toast(this.$t("messages.problemSave"), {
              title: "Error",
              variant: "danger",
              autoHideDelay: 5000,
            });
          });
      }
    },
  },
  mounted() {
    this.imagen = Math.floor(Math.random() * 3);
    if (
      process.env.VUE_APP_ENVIRONMENT === "PRODUCTION" &&
      location.protocol !== "https:"
    ) {
      location.replace(
        `https:${location.href.substring(location.protocol.length)}`
      );
    }
  },
};
