import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
  name: "PersonaPage",
  data() {
    let ss = new MainService();
    return {
      msg: "PersonaPage",
      ss: ss,
      ajax: {
        url: ss.indexPersona(),
        headers: ss.getToken(),
      },
      columns: [
        {
          data: "id",
          name: "p.id",
          orderable: false,
          searchable: false,
          visible: false,
        },
        {
          data: "DT_RowIndex",
          name: "DT_RowIndex",
          title: "N",
          orderable: false,
          searchable: false,
        },
        { data: "Entidad", name: "e.Entidad", title: "Entidad" },
        { data: "Persona", name: "p.Persona", title: "Persona" },
        { data: "Rol", name: "r.Rol", title: "Rol", searchable: false },
        {
          data: "Celular",
          name: "p.Celular",
          title: "Celular",
          searchable: false,
        },
        { data: "email", name: "p.email", title: "Email" },
        { data: "Pais", name: "p.Pais", title: "País" },
        {
          data: "created_at",
          name: "p.created_at",
          title: "Fecha creación",
          render: function (data, type, row) {
            if (row.created_at) {
              let date = row.created_at.split("T");
              return date[0];
            }
            return "";
          },
        },
        {
          data: "Activo",
          name: "p.Activo",
          title: "Estado",
          className: "centradito",
          render: function (data, type, row) {
            if (!row.Activo) {
              return '<i class="fa fa-ban text-danger"></i>';
            } else {
              return '<i class="fa fa-check text-success"></i>';
            }
          },
        },
        {
          data: "action",
          orderable: false,
          title: "Acciones",
          searchable: false,
        },
      ],
      entidads: [],
      rols: [],
      personas: [],
      persona: {},
      isLoading: false,
      isLoadingFile: false,
      errorBag: {},
      password: {},
    };
  },
  methods: {
    loadFile(input) {
      this.isLoadingFile = true;
      input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.readAsDataURL(input.files[0]);
        var data = new FormData();
        data.append("File", input.files[0]);
        this.ss
          .uploadFile(data)
          .then((result) => {
            if (result.data.success) {
              this.$bvToast.toast(result.data.msg, {
                title: this.$t("messages.success"),
                variant: "info",
                autoHideDelay: 5000,
              });
              this.persona.Foto = result.data.data;
            } else {
              this.$bvToast.toast(result.data.msg, {
                title: "Oops!",
                variant: "danger",
                autoHideDelay: 5000,
              });
            }
            this.isLoadingFile = false;
          })
          .catch((error) => {
            console.log(error);
            this.$bvToast.toast("Error subiendo archivo", {
              title: "Oops!",
              variant: "danger",
              autoHideDelay: 5000,
            });
            this.isLoadingFile = false;
          });
      }
    },
    getEntidad() {
      this.ss
        .listEntidad()
        .then((result) => {
          let response = result.data;
          this.entidads = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getRol() {
      this.ss
        .listRol()
        .then((result) => {
          let response = result.data;
          this.rols = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    newPersona() {
      this.persona = {};
      this.$refs["frm-persona"].show();
    },
    showPersona(id) {
      this.isLoading = true;
      this.ss
        .showPersona(id)
        .then((result) => {
          let response = result.data;
          this.persona = response.data;
          this.$refs["view-persona"].show();
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    editPersona() {
      this.$refs["frm-persona"].show();
      this.$refs["view-persona"].hide();
    },
    cancelPersona() {
      if (this.persona.id) {
        this.$refs["view-persona"].show();
      }
      this.$refs["frm-persona"].hide();
    },
    correoPersona() {
      this.ss
        .correoPersona({
          persona: this.persona.Persona,
          email: this.persona.email,
        })
        .then((result) => {
          console.log(result);
          let response = result.data;
          this.$bvToast.toast(response.msg, {
            title: this.$t("messages.success"),
            variant: "success",
            autoHideDelay: 5000,
          });
          //this.$refs['view-consulta'].show(); //para volver al modal
          console.log(response);
          this.$refs["frm-persona"].hide();
          this.$refs["datatable-persona"].reload();
        })
        .catch((error) => {
          this.errorBag = error.response.data.errors;
          this.$bvToast.toast("Problema al Validar el Correo", {
            title: "Error",
            variant: "danger",
            autoHideDelay: 5000,
          });
        });
    },
    savePersona() {
      this.ss
        .storePersona(this.persona)
        .then((result) => {
          console.log(result);
          let response = result.data;
          this.$bvToast.toast(response.msg, {
            title: this.$t("messages.success"),
            variant: "success",
            autoHideDelay: 5000,
          });
          //this.$refs['view-consulta'].show(); //para volver al modal
          console.log(response);
          this.$refs["frm-persona"].hide();
          this.$refs["datatable-persona"].reload();
        })
        .catch((error) => {
          this.errorBag = error.response.data.errors;
          this.$bvToast.toast(this.$t("messages.problemSave"), {
            title: "Error",
            variant: "danger",
            autoHideDelay: 5000,
          });
        });
    },
    deletePersona() {
      this.$swal({
        title: "Estas seguro que deseas eliminar?",
        text: "Esta accion es irreversible!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete.isConfirmed) {
          this.ss
            .destroyPersona(this.persona)
            .then((result) => {
              let response = result.data;
              console.log(response);
              this.$bvToast.toast(response.msg, {
                title: this.$t("messages.success"),
                variant: "success",
                autoHideDelay: 5000,
              });
              this.$refs["view-persona"].hide();
              this.$refs["datatable-persona"].reload();
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          //swal("Your imaginary file is safe!");
        }
      });
    },
    cambiopassword() {
      this.$refs["view-persona"].hide();
      this.$refs["view-password"].show();
    },
    cancelPassword() {
      this.$refs["view-password"].hide();
      this.$refs["view-persona"].show();
    },
    changePassword() {
      this.password.Persona = this.persona.id;
      this.ss
        .changePassword(this.password)
        .then((result) => {
          let response = result.data;
          if (response.success) {
            this.$bvToast.toast(response.msg, {
              title: this.$t("messages.success"),
              variant: "success",
              autoHideDelay: 5000,
            });
            this.$refs["view-password"].hide();
            this.$refs["view-persona"].show();
          } else {
            this.$bvToast.toast(response.msg, {
              title: "Oops",
              variant: "danger",
              autoHideDelay: 5000,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.$bvToast.toast("Error al guardar el registro", {
            title: "Oops",
            variant: "danger",
            autoHideDelay: 5000,
          });
          this.errorBag = error.data.errors;
        });
    },
    draw() {
      window.$(".btn-datatable-Persona").on("click", (evt) => {
        const data = window.$(evt.target)[0].id;
        this.showPersona(data);
      });
    },
  },
  components: {
    dataTable,
    Loader,
  },
  mounted() {
    var persona = JSON.parse(localStorage.getItem("persona"));
    if (!persona) {
      this.$router.push("/Admin/Login");
    } else {
      if (persona.Rol !== 1) {
        this.$router.push("/Admin/Login");
      }
      this.getEntidad();
      this.getRol();
    }
  },
};
