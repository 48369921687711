<template>
  <!-- begin navbar-collapse -->
  <div class="collapse navbar-collapse">
    <ul class="nav navbar-nav mt-2" style="float: left; margin-left: 64px">
      <li>
        <a
          v-bind:class="{ vainita: 1 === activo }"
          v-on:click="elejido(1)"
          href="#"
          @click.prevent="colorMix"
        >
          <i class="fa fa-fill-drip"></i> Color Mix
        </a>
      </li>
      <li>
        <a
          v-bind:class="{ vainita: 2 === activo }"
          v-on:click="elejido(2)"
          href="#"
          @click.prevent="misFormulas"
        >
          <i class="fa fa-palette"></i> {{ $t("nav.MyFormulas") }}</a
        >
      </li>
      <li>
        <a
          v-bind:class="{ vainita: 3 === activo }"
          v-on:click="elejido(3)"
          href="#"
          @click.prevent="misSugerencias"
        >
          <i class="fa fa-message mr-1 mr-md-0"></i>
          {{ $t("nav.MySuggestions") }}</a
        >
      </li>
    </ul>
    <ul class="nav navbar-nav mt-2" style="float: right">
      <li>
        <a
          style="border-radius: 45px; width: 45px; height: 45px"
          v-bind:class="{ vainita: 4 === activo, centradito }"
          v-on:click="elejido(4)"
          href="#"
          @click.prevent="misNotificaciones"
        >
          <i class="fa fa-bell mr-1 mr-md-0"></i
        ></a>
      </li>
    </ul>
  </div>
  <!-- end navbar-collapse -->
</template>

<script>
import PageOptions from "../../config/PageOptions.vue";

export default {
  name: "HeaderMegaMenu",
  data() {
    return {
      pageOptions: PageOptions,
      admin: "false",
      activo: 0,
    };
  },
  methods: {
    elejido: function (id) {
      this.activo = id;
    },
    misFormulas() {
      this.$router.push("/MisFormulas");
    },
    colorMix() {
      //this.removeClass("active");
      //this.parent().addClass("active");

      this.$router.push("/");
    },
    misSugerencias() {
      this.$router.push("/MisSugerencias");
    },
    misNotificaciones() {
      this.$router.push("/Notificaciones");
    },
  },
  mounted() {
    this.auth = JSON.parse(localStorage.getItem("persona"));
    this.admin = localStorage.getItem("admin");
  },
};
</script>
