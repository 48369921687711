import MainService from "@/services/MainService.js";
import PageOptions from "../../../config/PageOptions.vue";
import { Carousel3d, Slide } from "vue-carousel-3d";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import getBrightness from "getbrightness";
import Constants from "@/data/Constants.js";
import { VueEditor } from "vue2-editor";
import cloneDeep from "lodash.clonedeep";
import VSwatches from "vue-swatches";
import "vue-swatches/dist/vue-swatches.css";
import { hideAt, showAt } from "vue-breakpoints";

export default {
    name: "MisFormulasPage",
    data() {
        let ss = new MainService();
        let constants = new Constants();
        return {
            msg: "MisFormulasPage",
            ss: ss,
            auth: {},
            slide: 0,
            sliding: null,
            model: null,
            dialog: false,
            blanco: false,
            Pantone: {
                formula_magnum: [],
            },
            colors: [],
            colorsOriginal: [],
            fab: false,
            myOptionsPantone: [],
            myOptionsMagnum: constants.getListMagnum(),
            rutaApp: process.env.VUE_APP_MAIN_SERVICE,
            pantoneSelect: null,
            selectedPantoneHit: null,
            isDropdown2Visible: false,
            fabKey: "fab",
            loading: true,
            color: "#BDC3C8",
            size: "15px",
            margin: "2px",
            eliminados: [],
            radius: "100%",
            Magnum: {
                Cantidad: 0,
            },
            Comentario: {},
            search: "",
            units: [{
                    unit: "g",
                    data: this.$t("messages.kilogram"),
                },
                {
                    unit: "lb",
                    data: this.$t("messages.pounds"),
                },
            ],
            value: {
                Unit: "g",
                Cantidad: 1,
            },
            errorBag: {},
            customToolbar: [
                [{ font: [] }],
                ["bold", "italic", "underline", "strike"],
                [{ size: ["small", false, "large", "huge"] }],
                [{ list: "ordered" }, { list: "bullet" }],
                [{ indent: "-1" }, { indent: "+1" }],
            ],
        };
    },
    created() {
        PageOptions.pageWithMegaMenu = true;
        PageOptions.pageWithoutSidebar = true;
        if (this.auth.Rol == 5) {
            PageOptions.pageWithTopMenu = true;
        }
        //document.body.className = 'bg-white';
    },
    watch: {
        pantoneSelect(q) {
            /**/
            console.log(q);
        },
        selectedPantoneHit(persona) {
            const index = this.colors.findIndex((object) => {
                return object.id === persona.id;
            });
            console.log(index);
            this.goSlide(index);
        },
        Magnum: {
            handler(val) {
                if (val.Cantidad > 0) {
                    val.Cantidad = val.Cantidad / 1;
                }
            },
            deep: true,
        },
        // search: function(val) {
        //     let formula = [];
        //     for (let i = 0; i < this.colorsOriginal.length; i++) {
        //         if (this.colorsOriginal) {
        //             if (this.colorsOriginal[i].Pantone.toLowerCase().includes(val)) {
        //                 formula.push(this.colorsOriginal[i]);
        //             }
        //         }
        //     }
        //     this.colors = formula;
        // }
    },
    methods: {
        isEmpty(data) {
            return !data || data === "";
        },
        goSlide(index) {
            this.$refs.mycarousel.goSlide(index);
        },
        getCantidad(cantidad) {
            let cantidadInput;
            if (this.value.Cantidad) {
                cantidadInput = this.value.Cantidad;
                if (this.value.Unit == "g") {
                    cantidadInput = cantidadInput * 1000;
                }
                if (cantidadInput < 10) {
                    return ((parseFloat(cantidad) * cantidadInput) / 100).toFixed(3);
                }
                return ((parseFloat(cantidad) * cantidadInput) / 100).toFixed(2);
            } else {
                return parseFloat(cantidad).toFixed(2);
            }
        },
        divideCantidad(cantidad) {
            let auxCantidad = cantidad;
            if (auxCantidad > 0) {
                if (this.value.Unit == "g") {
                    auxCantidad = auxCantidad / this.value.Cantidad / 10;
                } else {
                    auxCantidad = (auxCantidad / this.value.Cantidad) * 100;
                }
            }
            return auxCantidad;
        },
        newComentario(pantone) {
            this.Comentario = {};
            let message =
                this.$t("messages.comment1") +
                pantone.Pantone +
                "</b>&nbsp;</p><p></p><ul>";
            try {
                for (let i = 0; i < pantone.formula_magnum.length; i++) {
                    message =
                        message +
                        "<li>" +
                        pantone.formula_magnum[i].magnum.Magnum +
                        " = " +
                        parseFloat(pantone.formula_magnum[i].Cantidad).toFixed(2) +
                        "%</li>";
                }
            } catch (e) {
                console.error(e);
            }
            this.Comentario.Descripcion =
                this.$t("messages.modification") + pantone.Pantone;
            this.Comentario.Html = message;
            this.$refs["frm-Comentario"].show();
        },
        cancelComentario() {
            if (this.Comentario.id) {
                this.$refs["view-Comentario"].show();
            }
            this.$refs["frm-Comentario"].hide();
        },
        saveComentario() {
            this.Comentario.Persona = this.auth.id;
            this.ss
                .storeComentario(this.Comentario)
                .then((result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(this.$t("messages.registerSaveDefault"), {
                        title: this.$t("messages.success"),
                        variant: "success",
                        autoHideDelay: 5000,
                    });
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs["frm-Comentario"].hide();
                    this.$refs["datatable-Comentario"].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(this.$t("messages.problemSave"), {
                        title: "Error",
                        variant: "danger",
                        autoHideDelay: 5000,
                    });
                });
        },

        converDate(input) {
            let parseDate = ''
            let dateIn = input.substring(0, 10)
            let date = dateIn.split('-')

            switch (date[1]) {
                case '01':
                    parseDate = this.$t("messages.january");
                    break;
                case '02':
                    parseDate = this.$t("messages.february");
                    break;
                case '03':
                    parseDate = this.$t("messages.march");
                    break;
                case '04':
                    parseDate = this.$t("messages.april");
                    break;
                case '05':
                    parseDate = this.$t("messages.may");
                    break;
                case '06':
                    parseDate = this.$t("messages.june");
                    break;
                case '07':
                    parseDate = this.$t("messages.july");
                    break;
                case '08':
                    parseDate = this.$t("messages.august");
                    break;
                case '09':
                    parseDate = this.$t("messages.september");
                    break;
                case '10':
                    parseDate = this.$t("messages.october");
                    break;
                case '11':
                    parseDate = this.$t("messages.november");
                    break;
                case '12':
                    parseDate = this.$t("messages.december");
                    break;
            }
            parseDate = `${parseDate} ${date[2]} ${this.$t("messages.dateOf")} ${date[0]}`;
            return parseDate
        },

        eliminar(color) {
            this.$swal({
                title: this.$t("messages.confirmationDelete"),
                text: this.$t("messages.thisActionIrreversible"),
                icon: "warning",
                showCancelButton: true,
                cancelButtonText: this.$t("messages.cancelInvert"),
                buttons: true,
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete.isConfirmed) {
                    this.ss
                        .destroyPantone(color)
                        .then((result) => {
                            let response = result.data;
                            console.log(response);
                            this.$bvToast.toast(this.$t("messages.registerDeleteDefault"), {
                                title: this.$t("messages.success"),
                                variant: "success",
                                autoHideDelay: 5000,
                            });
                            this.getColors(this.auth.id);
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                } else {
                    //swal("Your imaginary file is safe!");
                }
            });
        },

        newPantone(color) {
            this.eliminados = [];
            this.Pantone = {
                formula_magnum: [],
            };
            if (color) {
                let auxColor = cloneDeep(color);
                this.Pantone = auxColor;
                this.color = auxColor.ColorHex;
                this.Pantone.Nombre = auxColor.Pantone;
            }
            this.$refs["frm-PersonalizarFormula"].show();
        },
        cancelPantone() {
            this.$refs["frm-PersonalizarFormula"].hide();
        },
        saveTodo() {
            //aqui final

            if (
                this.isEmpty(this.Pantone.Nombre) ||
                this.Pantone.formula_magnum.length === 0
            ) {
                this.$bvToast.toast(this.$t("messages.nameFormulaRequired"), {
                    title: "Error",
                    variant: "danger",
                    autoHideDelay: 5000,
                });
                return;
            }

            let alguiyo = 2;
            if (alguiyo == 2) {
                this.Pantone.Hexadecimal = this.color;
                this.Pantone.RGB = null;
                this.Pantone.eliminados = this.eliminados;
                this.Pantone.Pantone = this.Pantone.Nombre;
                this.Pantone.Activo = true;
                this.ss
                    .storePantone(this.Pantone)
                    .then((result) => {
                        let response = result.data;
                        this.PantoneRespuesta = response.data;
                        console.log("PantoneID:", this.PantoneRespuesta.id);
                        let total = 0;
                        let cantidad = 0;
                        for (let i = 0; i < this.Pantone.formula_magnum.length; i++) {
                            cantidad = parseFloat(this.Pantone.formula_magnum[i].Cantidad);
                            total = total + cantidad;
                        }

                        for (let i = 0; i < this.Pantone.formula_magnum.length; i++) {
                            this.ss
                                .storeFormula({
                                    Pantone: this.PantoneRespuesta.id,
                                    Magnum: this.Pantone.formula_magnum[i]["Magnum"],
                                    id: this.Pantone.formula_magnum[i]["id"] ?
                                        this.Pantone.formula_magnum[i]["id"] : null,
                                    Cantidad: parseFloat(
                                        (parseFloat(this.Pantone.formula_magnum[i]["Cantidad"]) /
                                            total) *
                                        100
                                    ).toFixed(2),
                                    Blanco: this.blanco,
                                    Original: false, //ojo aqui
                                    Persona: this.auth.id,
                                })
                                .then((result) => {
                                    let response = result.data;
                                    console.log(response.data.Fecha);
                                })
                                .catch((error) => {
                                    this.errorBag = error.response.data.errors;
                                    this.$bvToast.toast(this.$t("messages.problemSave"), {
                                        title: "Error",
                                        variant: "danger",
                                        autoHideDelay: 5000,
                                    });
                                });
                        }

                        // console.log('viene aca>>>>>>>>>>>')
                        this.$bvToast.toast(this.$t("messages.saveColorSuccessUpperCase"), {
                            title: this.$t("messages.success"),
                            variant: "success",
                        });

                        //window.location.reload();
                        this.$refs["frm-PersonalizarFormula"].hide();
                        this.getColors(this.auth.id);
                    })
                    .catch((error) => {
                        this.errorBag = error.response.data.errors;
                        this.$bvToast.toast(this.$t("messages.problemSave"), {
                            title: "Error",
                            variant: "danger",
                            autoHideDelay: 5000,
                        });
                    });
            }
        },
        savePantone() {
            //TODO:
            this.ss
                .storeComentario(this.Comentario)
                .then((result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(this.$t("messages.registerSaveDefault"), {
                        title: this.$t("messages.success"),
                        variant: "success",
                        autoHideDelay: 5000,
                    });
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs["frm-Comentario"].hide();
                    this.$refs["datatable-Comentario"].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(this.$t("messages.problemSave"), {
                        title: "Error",
                        variant: "danger",
                        autoHideDelay: 5000,
                    });
                });
        },

        newMagnum(magnum, cantidad, index) {
            this.Magnum = {
                Cantidad: 0,
            };
            this.errorBag = {};
            if (magnum && (index || index == 0)) {
                this.Magnum.id = magnum;
                this.Magnum.index = index;
            }
            if (cantidad && (index || index == 0)) {
                this.Magnum.Cantidad = this.getCantidad(cantidad);
            }
            this.$refs["frm-Magnum"].show();
            this.$refs["frm-PersonalizarFormula"].hide();
        },
        cancelMagnum() {
            this.$refs["frm-Magnum"].hide();
            this.$refs["frm-PersonalizarFormula"].show();
        },
        saveMagnum() {
            this.errorBag = {};
            if (!this.Magnum.id || this.Magnum.Cantidad <= 0) {
                if (!this.Magnum.id) {
                    this.errorBag.Magnum = this.$t("messages.magnumRequired");
                }
                if (this.Magnum.Cantidad <= 0) {
                    this.errorBag.Cantidad = this.$t("messages.quantityRequired");
                }
                this.$forceUpdate();
            } else {
                if (this.Magnum.index || this.Magnum.index == 0) {
                    let index = this.Magnum.index;
                    this.Pantone.formula_magnum[index].idFormula =
                        this.Pantone.formula_magnum[index].id;
                    this.Pantone.formula_magnum[index].Magnum = this.Magnum.id;
                    this.Pantone.formula_magnum[index].magnum.Magnum =
                        this.myOptionsMagnum[this.Magnum.id - 1].magnum;
                    this.Pantone.formula_magnum[index].magnum.ColorHex =
                        this.myOptionsMagnum[this.Magnum.id - 1].hex;
                    this.Pantone.formula_magnum[index].Cantidad = this.divideCantidad(
                        this.Magnum.Cantidad
                    );
                } else {
                    let aux = {
                        Magnum: 0,
                        magnum: {
                            Magnum: "",
                        },
                        Cantidad: 0,
                    };
                    aux.Magnum = this.Magnum.id;
                    aux.idFormula = null;
                    aux.magnum.id = this.Magnum.id;
                    aux.magnum.Magnum = this.myOptionsMagnum[this.Magnum.id - 1].magnum;
                    aux.magnum.ColorHex = this.myOptionsMagnum[this.Magnum.id - 1].hex;
                    aux.Cantidad = this.divideCantidad(this.Magnum.Cantidad);
                    this.Pantone.formula_magnum.push(aux);
                }
                this.$refs["frm-Magnum"].hide();
                this.$refs["frm-PersonalizarFormula"].show();
            }
        },
        deleteMagnum(index) {
            if (this.Pantone.formula_magnum[index].id) {
                this.eliminados.push(this.Pantone.formula_magnum[index].id);
            }
            this.Pantone.formula_magnum.splice(index, 1);
        },

        newColor() {
            this.color = "#BDC3C8";
            this.$refs["frm-Color"].show();
            this.$refs["frm-PersonalizarFormula"].hide();
        },

        checkForm: function(e) {
            e.preventDefault();
        },
        onSlideStart() {
            this.sliding = true;
        },
        onSlideEnd() {
            this.sliding = false;
        },
        changeValueCheckBox() {
            console.log("entraaa");
            //this.blanco ? this.colors = this.coloresBlanco : this.colors = this.coloresNegro;
        },
        pdfFormula(tipo, pantone, blanco) {
            if (this.pantone === null) {
                this.$swal({
                    icon: "warning",
                    title: this.$t("messages.warning"),
                    text: this.$t("messages.chooseValidPantone"),
                    showConfirmButton: false,
                    timer: 1500,
                });
                return;
            }
            let cantidadAux = this.getCantidadQuery();
            var urlFile =
                this.rutaApp +
                "api/PantonePDF?Pantone=" +
                pantone +
                "&Blanco=" +
                blanco +
                "&Fondo=" +
                false +
                "&TipoM=" +
                tipo +
                "&Lang=" +
                this.$i18n.locale +
                "&Tipo=" +
                this.value.Unit +
                "&Total=" +
                cantidadAux;
            window.open(urlFile);
        },
        getCantidadQuery() {
            if (this.value.Unit === "g") {
                return this.value.Cantidad * 1000;
            } else {
                return this.value.Cantidad;
            }
        },
        getColors(persona) {
            this.loading = true;
            this.ss
                .listPersonaFormula(persona)
                .then((result) => {
                    let response = result.data;
                    this.colors = response;
                    this.colorsOriginal = response;
                    this.loading = false;
                    this.myOptionsPantone = this.colorsOriginal;
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                });
        },
        getBrightnessValue(hex) {
            return getBrightness(hex) > 0.6 ? "black" : "white";
        },
    },
    components: {
        Carousel3d,
        Slide,
        PulseLoader,
        VueEditor,
        VSwatches,
        hideAt,
        showAt,
    },
    mounted() {
        if (
            process.env.VUE_APP_ENVIRONMENT === "PRODUCTION" &&
            location.protocol !== "https:"
        ) {
            location.replace(
                `https:${location.href.substring(location.protocol.length)}`
            );
        }
        this.auth = JSON.parse(localStorage.getItem("persona"));
        if (!this.auth) {
            this.$router.push("/Login");
        } else {
            this.getColors(this.auth.id);
        }
        this.$root.$on("bv::dropdown::show", (bvEvent) => {
            if (bvEvent.componentId === "dropdown-2") {
                this.isDropdown2Visible = true;
            }
        });
        this.$root.$on("bv::dropdown::hide", (bvEvent) => {
            if (bvEvent.componentId === "dropdown-2") {
                this.isDropdown2Visible = false;
            }
            if (this.isDropdown2Visible) {
                bvEvent.preventDefault();
            }
        });
    },
};