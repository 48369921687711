import Vue from "vue";
import routes from "./config/PageRoutes";
import i18n from "./i18n";

// plugins
import VueRouter from "vue-router";
import VueBootstrap from "bootstrap-vue";
import VueInsProgressBar from "vue-ins-progress-bar";
import VueEventCalendar from "vue-event-calendar";
import VueSparkline from "vue-sparklines";
import VueHljs from "vue-hljs";
import VueSweetalert2 from "vue-sweetalert2";
import VueNotification from "vue-notification";
import VuePanel from "./plugins/panel/";
import VueDateTimePicker from "vue-bootstrap-datetimepicker";
import VueSelect from "vue-select";
import VueDatepicker from "vuejs-datepicker/dist/vuejs-datepicker.esm.js";
import VueMaskedInput from "vue-maskedinput";
import VueInputTag from "vue-input-tag";
import VueSlider from "vue-slider-component";
import VueCountdown from "@chenfengyuan/vue-countdown";
import VueColorpicker from "vue-pop-colorpicker";
import VueCustomScrollbar from "vue-custom-scrollbar";
import DateRangePicker from "vue2-daterange-picker";
import FlagIcon from "vue-flag-icon";

// plugins css
import "bootstrap-vue/dist/bootstrap-vue.css";
import "vue-event-calendar/dist/style.css";
import "vue-hljs/dist/vue-hljs.min.css";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import "simple-line-icons/css/simple-line-icons.css";
import "flag-icon-css/css/flag-icon.min.css";
import "ionicons/dist/css/ionicons.min.css";
import "vue-select/dist/vue-select.css";
import "vue-slider-component/theme/antd.css";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

import VueBootstrapTypeahead from "vue-bootstrap-typeahead";

// color admin css
import "./assets/css/default/app.min.css";
import "./scss/vue.scss";
import "bootstrap-social/bootstrap-social.css";

import App from "./App.vue";
import vuetify from "./plugins/vuetify";
//import Carousel3d from 'vue-carousel-3d';

import {
  Swiper as SwiperClass,
  Pagination,
  Mousewheel,
  Autoplay,
} from "swiper/swiper.esm";
import getAwesomeSwiper from "vue-awesome-swiper/dist/exporter";

// Swiper modules
SwiperClass.use([Pagination, Mousewheel, Autoplay]);

// -------------------------------------------------

// Global use
Vue.use(getAwesomeSwiper(SwiperClass));

// import style
import "swiper/swiper-bundle.min.css";
// import "swiper/swiper.min.css";

//Vue.use(VueAwesomeSwiper /* { default options with global component } */);

//Vue.use(Carousel3d);
Vue.config.productionTip = false;

Vue.use(VueRouter);
Vue.use(VueBootstrap);
Vue.use(VueEventCalendar, { locale: "en" });
Vue.use(VueSparkline);
Vue.use(VueHljs);
Vue.use(VueSweetalert2);
Vue.use(VueNotification);
Vue.use(VuePanel);
Vue.use(FlagIcon);
Vue.use(VueDateTimePicker);
Vue.use(VueColorpicker);
Vue.use(VueInsProgressBar, {
  position: "fixed",
  show: true,
  height: "3px",
});
Vue.component("v-select", VueSelect);
Vue.component("datepicker", VueDatepicker);
Vue.component("masked-input", VueMaskedInput);
Vue.component("input-tag", VueInputTag);
Vue.component("vue-slider", VueSlider);
Vue.component("vue-custom-scrollbar", VueCustomScrollbar);
Vue.component("date-range-picker", DateRangePicker);
Vue.component(VueCountdown.name, VueCountdown);
Vue.component("vue-bootstrap-typeahead", VueBootstrapTypeahead);

Vue.directive("validity", function (el, binding) {
  el.setCustomValidity(binding.value ? binding.value : "");
});

const router = new VueRouter({
  routes,
  mode: "history",
});

new Vue({
  render: (h) => h(App),
  router,
  vuetify,
  i18n,
}).$mount("#app");
