import MainService from "@/services/MainService.js";
import PageOptions from "../../../config/PageOptions.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { VueEditor } from "vue2-editor";
import { hideAt, showAt } from "vue-breakpoints";

export default {
  name: "MisSugerenciasPage",
  data() {
    let ss = new MainService();
    return {
      msg: "MisSugerenciasPage",
      ss: ss,
      auth: {},
      slide: 0,
      sliding: null,
      noticias: [],
      noticia: {},
      model: null,
      fab: false,
      fabKey: "fab",
      loading: true,
      color: "#8753de",
      size: "15px",
      margin: "2px",
      radius: "100%",
      comentario: {},
      errorBag: {},
      customToolbar: [
        ["bold", "italic", "underline", "strike"],
        [{ size: ["small", false, "large", "huge"] }],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
    };
  },
  created() {
    PageOptions.pageWithMegaMenu = true;
    PageOptions.pageWithoutSidebar = true;
    if (this.auth.Rol == 5) {
      PageOptions.pageWithTopMenu = true;
    }
    //document.body.className = 'bg-white';
  },
  methods: {
    newComentario() {
      this.comentario = {};
      this.$refs["frm-Comentario"].show();
    },
    cancelComentario() {
      if (this.comentario.id) {
        this.$refs["view-Comentario"].show();
      }
      this.$refs["frm-Comentario"].hide();
    },
    saveComentario() {
      this.comentario.Persona = this.auth.id;
      this.ss
        .storeComentario(this.comentario)
        .then((result) => {
          console.log(result);
          let response = result.data;
          this.$bvToast.toast(this.$t("messages.registerSaveDefault"), {
            title: this.$t("messages.success"),
            variant: "success",
            autoHideDelay: 5000,
          });
          //this.$refs['view-consulta'].show(); //para volver al modal
          console.log(response);
          this.$refs["frm-Comentario"].hide();
          this.getSugerencias(this.auth.id);
        })
        .catch((error) => {
          this.errorBag = error.response.data.errors;
          this.$bvToast.toast(this.$t("messages.problemSave"), {
            title: "Error",
            variant: "danger",
            autoHideDelay: 5000,
          });
        });
    },
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
    openModal(item) {
      this.noticia = item;
      this.$refs["view-noticia"].show();
    },
    getSugerencias(persona) {
      this.loading = true;
      this.ss
        .listComentario(persona)
        .then((result) => {
          let response = result.data;
          this.noticias = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
  },
  components: { PulseLoader, VueEditor, hideAt, showAt },
  mounted() {
    if (
      process.env.VUE_APP_ENVIRONMENT === "PRODUCTION" &&
      location.protocol !== "https:"
    ) {
      location.replace(
        `https:${location.href.substring(location.protocol.length)}`
      );
    }
    this.auth = JSON.parse(localStorage.getItem("persona"));
    if (!this.auth) {
      this.$router.push("/Login");
    } else {
      this.getSugerencias(this.auth.id);
    }
  },
};
