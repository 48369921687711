<script>
import AdminLogin from "@/pages/Admin/Login/LoginPage.vue";
import AdminEntidad from "@/pages/Admin/Entidad/EntidadPage.vue";
import AdminRol from "@/pages/Admin/Rol/RolPage.vue";
import AdminPersona from "@/pages/Admin/Persona/PersonaPage.vue";
import AdminPrincipal from "@/pages/Admin/Principal/PrincipalPage.vue";
import AdminProfile from "@/pages/Admin/Profile/ProfilePage.vue";
import AdminTipoReporte from "@/pages/Admin/TipoReporte/TipoReportePage.vue";
import AdminTipoReporteVer from "@/pages/Admin/TipoReporteVer/TipoReporteVerPage.vue";
import AdminPantone from "@/pages/Admin/Pantone/PantonePage.vue";
import AdminFormula from "@/pages/Admin/Formula/FormulaPage.vue";
import AdminNoticia from "@/pages/Admin/Noticia/NoticiaPage.vue";
import AdminMagnum from "@/pages/Admin/Magnum/MagnumPage.vue";
import AdminComentario from "@/pages/Admin/Comentario/ComentarioPage.vue";

import Login from "@/pages/Users/Login/LoginPage.vue";
import Principal from "@/pages/Users/Principal/PrincipalPage.vue";
import Register from "@/pages/Users/Register/RegisterPage.vue";
import RememberPassword from "@/pages/Users/RememberPassword/RememberPasswordPage.vue";
import MisFormulas from "@/pages/Users/MisFormulas/MisFormulasPage.vue";
import MisSugerencias from "@/pages/Users/MisSugerencias/MisSugerenciasPage.vue";
import Notificaciones from "@/pages/Users/Notificaciones/NotificacionesPage.vue";
import Profile from "@/pages/Users/Profile/ProfilePage.vue";

const routes = [
  // starter

  { path: "/", component: Principal },
  { path: "/Login", component: Login },
  { path: "/Register", component: Register },
  { path: "/RememberPassword", component: RememberPassword },
  { path: "/MisFormulas", component: MisFormulas },
  { path: "/Notificaciones", component: Notificaciones },
  { path: "/MisSugerencias", component: MisSugerencias },
  { path: "/Profile", component: Profile },

  { path: "/Admin/Persona", component: AdminPersona },
  //{ path: "/Admin/Profile", component: AdminProfile },
  { path: "/Admin/Login", component: AdminLogin },
  { path: "/Admin/Entidad", component: AdminEntidad },
  { path: "/Admin/Rol", component: AdminRol },
  { path: "/Admin/", component: AdminPersona },
  { path: "/Admin/TipoReporte", component: AdminTipoReporte },
  { path: "/Admin/Reportes", component: AdminTipoReporteVer },
  { path: "/Admin/Pantone", component: AdminPantone },
  { path: "/Admin/Formula", component: AdminFormula },
  { path: "/Admin/Noticias", component: AdminNoticia },
  { path: "/Admin/Magnum", component: AdminMagnum },
  //{ path: "/Admin/Comentarios", component: AdminComentario },
];

export default routes;
</script>
