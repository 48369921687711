import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
  name: "FormulaPage",
  data() {
    let ss = new MainService();
    return {
      msg: "FormulaPage",
      ss: ss,
      ajax: {
        url: ss.indexFormula(),
        headers: ss.getToken(),
      },
      columns: [
        {
          data: "id",
          name: "p.id",
          orderable: false,
          searchable: false,
          visible: false,
        },
        {
          data: "DT_RowIndex",
          name: "DT_RowIndex",
          title: "N",
          orderable: false,
          searchable: false,
        },
        { data: "Pantone", name: "p.Pantone", title: "Pantone" },
        {
          data: "RGB",
          searchable: false,
          name: "p.RGB",
          title: "Pantone Color",
          render: function (data, type, row) {
            if (row.RGB) {
              return (
                '<div style="background-color:rgb(' +
                row.RGB +
                ');"> </br></br></div>'
              );
            } else {
              return '<div style="background-color:red;"> </br>ERROR COLOR</br></div>';
            }
          },
        },
        /*            { data: 'cMagnum', searchable: false, name: 'cMagnum', title: 'Magnum Color' , render:function(data, type, row){
                            if (row.cMagnum){
                                return '<div style="background-color:rgb('+row.cMagnum+');"> </br></br></div>';
                            } else{ return '<div style="background-color:red;"> </br>ERROR COLOR</br></div>';}} }, 
                        //{ data: 'Magnum', name: 'm.Magnum', title: 'Magnum' },
                        //{ data: 'Codigo', name: 'm.Codigo', title: 'Codigo' },
                        //{ data: 'Cantidad', name: 'f.Cantidad', title: 'Cantidad' },
                        //{ data: 'Cantidad', name: 'f.Cantidad', title: 'Porcentaje' },
                        { data: 'blanco', name: 'blanco', title: 'Blanco' , render:function(data, type, row){
                            if (row.blanco>0){
                                return row.blanco;}
                            else {return 'SIN DATOS';}}},
                        { data: 'negro', name: 'negro', title: 'Negro' , render:function(data, type, row){
                                if (row.negro>0){
                                    return row.negro;}
                                else {return 'SIN DATOS';}}},*/
        { data: "Persona", name: "pp.Persona", title: "Creador" },
        { data: "blanco", name: "blanco", title: "blanco" },
        {
          data: "Original",
          name: "f.Original",
          title: "Origen",
          className: "centradito",
          render: function (data, type, row) {
            if (row.Original) {
              return "EMPRESA";
            } else {
              return "EXTERNO";
            }
          },
        },
        {
          data: "action",
          orderable: false,
          title: "Acciones",
          searchable: false,
        },
        /*,
                        {
                            data: 'pdf',
                            orderable: false,
                            title: 'PDF',
                            searchable: false,
                            
                        } */
      ],
      formulas: [],
      pantones: [],
      pantone: [],
      magnums: [],
      magnum: [],
      arraymagnum: [],
      formula: {},
      isLoading: false,
      errorBag: {},
      fmagnum: ["Magnum", "Codigo"],
      imagnum: {},
    };
  },
  methods: {
    getPantone() {
      this.ss
        .listPantone()
        .then((result) => {
          let response = result.data;
          this.pantones = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getColor() {
      this.ss
        .showPantone(this.formula.Pantone)
        .then((result) => {
          let response = result.data;
          this.pantone = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getMagnum() {
      this.ss
        .listMagnum()
        .then((result) => {
          let response = result.data;
          this.magnums = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getHijoColor() {
      this.ss
        .showMagnum(this.formula.Magnum)
        .then((result) => {
          let response = result.data;
          this.magnum = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addFila() {
      if (this.formula.Magnum) {
        this.arraymagnum.push({
          Magnum: this.magnum.Magnum,
          Color: this.magnum.ColorHex,
          Cantidad: this.formula.Cantidad,
          id: null,
          deleted_at: null,
        });
        this.formula.Magnum = null;
      }
    },
    deleteFila(id, num) {
      if (id) {
        let fun = this.arraymagnum[num];
        this.arraymagnum[num].deleted_at = 1;
        console.log("opcion", id, num, fun);
      } else {
        this.arraymagnum.splice(num, 1);
        //delete this.arrayfuncion[num];
      }
    },
    showPantone(id, fondo) {
      console.log(id, fondo);
      let Blanco;
      if (fondo == 1) {
        Blanco = true;
      } else if (fondo == 2) {
        Blanco = false;
      } else {
        Blanco = null;
      }
      this.isLoading = true;
      this.ss
        .showFormula({ params: { id: id, Blanco: Blanco } })
        .then((result) => {
          let response = result.data;
          this.formula = response.data;
          this.$refs["view-formula"].show();
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    newFormula() {
      this.formula = {};
      this.pantone = {};
      this.$refs["frm-formula"].show();
    },
    /*showFormula(id) {
                this.isLoading=true;
                this.ss.showFormula(id).then(
                    (result) => {
                        let response = result.data;
                        this.formula = response.data;
                        this.$refs['view-formula'].show();
                        this.isLoading=false;
                    }
                ).catch(error => {
                    console.log(error);
                    this.isLoading=false;
                });
            },*/
    editFormula() {
      this.$refs["frm-formula"].show();
      this.$refs["view-formula"].hide();
    },
    cancelFormula() {
      if (this.formula.id) {
        this.$refs["view-formula"].show();
      }
      this.$refs["frm-formula"].hide();
    },
    saveFormula() {
      this.ss
        .storeFormula(this.formula)
        .then((result) => {
          console.log(result);
          let response = result.data;
          this.$bvToast.toast(response.msg, {
            title: this.$t("messages.success"),
            variant: "success",
            autoHideDelay: 5000,
          });
          //this.$refs['view-consulta'].show(); //para volver al modal
          console.log(response);
          this.$refs["frm-formula"].hide();
          this.$refs["datatable-formula"].reload();
        })
        .catch((error) => {
          this.errorBag = error.response.data.errors;
          this.$bvToast.toast(this.$t("messages.problemSave"), {
            title: "Error",
            variant: "danger",
            autoHideDelay: 5000,
          });
        });
    },
    newPdf(id, fondo) {
      //console.log('imprimir',id,fondo);
      let Blanco;
      if (fondo == 1) {
        Blanco = true;
      } else if (fondo == 2) {
        Blanco = false;
      } else {
        Blanco = null;
      }
      this.ss
        .pdfFormula({
          params: { Pantone: id, Blanco: Blanco, Lang: this.$i18n.locale },
        })
        .then((result) => {
          let response = result.data;
          //console.log(response);
          window.open(response, "_blank");

          //create a link to download the pdf file
          /*        var link=document.createElement('a');
                          //i used the base url to target a file on mi proyect folder
                          link.href=window.URL = base_url+"exportacion.pdf";
                          //download the name with a different name given in the php method
                          link.download=data.nombre_archivo;
                          link.click();
                          //this js function hides the loading gif i use
                          hideLoad();*/
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    deleteFormula() {
      this.$swal({
        title: "Estas seguro que deseas eliminar?",
        text: "Esta accion es irreversible!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete.isConfirmed) {
          this.ss
            .destroyFormula(this.formula)
            .then((result) => {
              let response = result.data;
              console.log(response);
              this.$bvToast.toast(response.msg, {
                title: this.$t("messages.success"),
                variant: "success",
                autoHideDelay: 5000,
              });
              this.$refs["view-formula"].hide();
              this.$refs["datatable-formula"].reload();
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          //swal("Your imaginary file is safe!");
        }
      });
    },
    draw() {
      window.$(".btn-datatable-Formula").on("click", (evt) => {
        //console.log(window.$(evt.target)[0]);
        const data = window.$(evt.target)[0].id;
        //const tipo = window.$(evt.target)[0].name;
        this.showPantone(data);
      });

      /* window.$('.btn-datatable-pdf').on('click', (evt) => {
                      let data = window.$(evt.target)[0].id;
                      let tipo = window.$(evt.target)[0].name;
                      //console.log(data)
                      this.newPdf(data,tipo);
                  });*/
    },
  },
  components: {
    dataTable,
    Loader,
  },
  mounted() {
    var persona = JSON.parse(localStorage.getItem("persona"));
    if (!persona) {
      this.$router.push("/Admin/Login");
    } else {
      if (persona.Rol !== 1) {
        this.$router.push("/Admin/Login");
      }
      this.getPantone();
      this.getMagnum();
    }
  },
};
