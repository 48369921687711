var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"register register-with-news-feed"},[_c('div',{staticClass:"news-feed"},[_c('div',{staticClass:"news-image",staticStyle:{"background-image":"url(/assets/img/fondos/register.png)"}}),_c('div',{staticClass:"news-caption"},[_vm._m(0),_c('p',[_vm._v(" "+_vm._s(_vm.$t("messages.loginMessage"))+" ")])])]),_c('div',{staticClass:"right-content"},[_c('img',{attrs:{"height":"25%","width":"25%","src":"/assets/img/logo_base.png","alt":""}}),_c('br'),_c('br'),_c('h1',{staticClass:"register-header"},[_vm._v(" "+_vm._s(_vm.$t("messages.recoveryPassword"))+" "),_c('small',[_vm._v(_vm._s(_vm.$t("messages.forgotPassword")))])]),_c('div',{staticClass:"register-content"},[_c('form',{staticClass:"margin-bottom-0",attrs:{"method":"POST"},on:{"submit":_vm.checkForm}},[_vm._m(1),_c('div',{staticClass:"row m-b-10"},[_c('div',{staticClass:"col-md-12"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.loginRequest.email),expression:"loginRequest.email"},{name:"validity",rawName:"v-validity",value:(
                _vm.isEmpty(_vm.loginRequest.email)
                  ? `${_vm.$t('messages.emailRequired')}`
                  : ''
              ),expression:"\n                isEmpty(loginRequest.email)\n                  ? `${$t('messages.emailRequired')}`\n                  : ''\n              "}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Email"},domProps:{"value":(_vm.loginRequest.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.loginRequest, "email", $event.target.value)}}})])]),_c('div',{staticClass:"register-buttons"},[_c('button',{staticClass:"btn btn-primary btn-block btn-lg",attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("messages.recoveryPassword"))+" ")])]),_c('div',{staticClass:"m-t-20 m-b-40 p-b-40 text-inverse"},[_vm._v(" "+_vm._s(_vm.$t("messages.alreadyMember"))+" "),_c('router-link',{attrs:{"to":"/Login"}},[_vm._v(_vm._s(_vm.$t("messages.here")))]),_vm._v(" "+_vm._s(_vm.$t("messages.forLogin"))+". ")],1),_c('hr')])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('h4',{staticClass:"caption-title"},[_c('b',[_vm._v("Magnum")]),_vm._v(" Mixing System")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('label',{staticClass:"control-label"},[_vm._v("Email "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
}]

export { render, staticRenderFns }