<template>
  <!-- begin #footer -->
  <div id="footer" class="footer" style="background-color: white">
    <img
      height="32px"
      width="32px"
      style="margin-right: 16px; margin-left: 16px"
      src="/assets/img/logo_base.png"
      alt=""
    />

    &copy; Magnum Mixing System -
    <b> Technical Internacional S.A.C.</b>
    <b>{{ $t("messages.allRightsReserved") }} </b>
    <b>{{ new Date().getFullYear() }}</b>
  </div>
  <!-- end #footer -->
</template>

<script>
import PageOptions from "../../config/PageOptions.vue";

export default {
  name: "Footer",
  data() {
    return {
      pageOptions: PageOptions,
    };
  },
};
</script>
