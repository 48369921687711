import MainService from "@/services/MainService.js";
import Constants from "@/data/Constants.js";
import PageOptions from "../../../config/PageOptions.vue";
import { Carousel3d, Slide } from "vue-carousel-3d";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import getBrightness from "getbrightness";
import cloneDeep from "lodash.clonedeep";
import VSwatches from "vue-swatches";
import "vue-swatches/dist/vue-swatches.css";
import { showAt } from "vue-breakpoints";
import { clearCache } from "clear-cache";
import StorageMethods from "@/utils/StorageMethods.js";
import { VueEditor } from "vue2-editor";

export default {
    name: "PrincipalPage",
    data() {
        let constants = new Constants();
        let ss = new MainService();
        let storage = new StorageMethods();
        return {
            msg: "PrincipalPage",
            ss: ss,
            auth: {},
            slide: 0,
            storage: storage,
            sliding: null,
            model: null,
            rutaApp: process.env.VUE_APP_MAIN_SERVICE,
            blanco: false,
            isLoading: false,
            pantoneSelect: "",
            Magnum: {
                Cantidad: 0,
            },
            Pantone: {
                formula_magnum: [],
            },
            Comentario: {},
            picker: null,
            myOptionsPantone: constants.getListPantone(),
            myOptionsMagnum: constants.getListMagnum(),
            placeholderPantone: "",
            selectedPantoneHit: null,
            units: [{
                    unit: "g",
                    data: this.$t("messages.kilogram"),
                },
                {
                    unit: "lb",
                    data: this.$t("messages.pounds"),
                },
            ],
            value: {
                Unit: "g",
                Cantidad: 1,
            },
            coloresBlanco: [],
            coloresNegro: [],
            colors: [],
            loading: true,
            color: "#BDC3C8",
            size: "15px",
            margin: "2px",
            radius: "100%",
            errorBag: {},
            currentZoom: {},
            PantoneRespuesta: {},
            comentario: {},
            customToolbar: [
                ["bold", "italic", "underline", "strike"],
                [{ size: ["small", false, "large", "huge"] }],
                [{ list: "ordered" }, { list: "bullet" }],
            ],
        };
    },
    created() {
        PageOptions.pageWithMegaMenu = true;
        PageOptions.pageWithoutSidebar = true;
        if (this.auth.Rol == 5) {
            PageOptions.pageWithTopMenu = true;
        }
        document.addEventListener("beforeunload", this.handler);
    },
    computed: {
        dynamicHeaderColor(red, green, blue) {
            return {
                color: `rgb(${red}, ${green}, ${blue});`,
            };
        },
    },
    methods: {
        listPantoneOriginal() {
            this.ss
                .listPantoneOriginal()
                .then((result) => {
                    let response = result.data;
                    let lista = response.data;
                    if (lista && lista.length > 0) {
                        let listaAux = lista.map((value) => value.Pantone);
                        //console.log("lista auxiliar", listaAux);
                        this.myOptionsPantone = listaAux;
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                });
        },
        openZoom(pantone) {
            this.currentZoom = pantone;
            this.$refs["view-zoom"].show();
        },
        cancelZoom() {
            this.currentZoom = {};
            this.$refs["view-zoom"].hide();
        },
        agregarComentario(pantone) {
            this.comentario = {};
            let message =
                this.$t("messages.comment1") +
                pantone.Pantone +
                "</b>&nbsp;</p><p></p><ul>";
            this.comentario.Descripcion =
                this.$t("messages.modification") + pantone.Pantone;
            this.comentario.Html = message;
            console.log("es un comentario", this.comentario);
            this.$refs["frm-Comentario"].show();
        },
        cancelComentario() {
            if (this.comentario.id) {
                this.$refs["view-Comentario"].show();
            }
            this.$refs["frm-Comentario"].hide();
        },
        saveComentario() {
            this.comentario.Persona = this.auth.id;
            this.ss
                .storeComentario(this.comentario)
                .then((result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(this.$t("messages.registerSaveDefault"), {
                        title: this.$t("messages.success"),
                        variant: "success",
                        autoHideDelay: 5000,
                    });
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs["frm-Comentario"].hide();
                    this.getSugerencias(this.auth.id);
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(this.$t("messages.problemSave"), {
                        title: "Error",
                        variant: "danger",
                        autoHideDelay: 5000,
                    });
                });
        },

        getCantidad(cantidad) {
            let cantidadInput;
            if (this.value.Cantidad > 0) {
                cantidadInput = this.value.Cantidad;
                if (this.value.Unit == "g") {
                    cantidadInput = cantidadInput * 1000;
                }
                if (cantidadInput < 10) {
                    return ((parseFloat(cantidad) * cantidadInput) / 100).toFixed(3);
                }
                return ((parseFloat(cantidad) * cantidadInput) / 100).toFixed(2);
            } else {
                return parseFloat(cantidad).toFixed(2);
            }
        },

        newPantone(color) {
            let auxColor = cloneDeep(color);
            this.Pantone = auxColor;
            this.color = auxColor.ColorHex;
            this.Pantone.id = null;
            this.Pantone.Nombre =
                auxColor.Pantone + " " + this.$t("messages.modified");
            this.$refs["frm-PersonalizarFormula"].show();
        },
        cancelPantone() {
            this.$refs["frm-PersonalizarFormula"].hide();
        },
        savePantone() {
            //TODO:
            this.ss
                .storeComentario(this.Comentario)
                .then((result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(this.$t("messages.registerSaveDefault"), {
                        title: this.$t("messages.success"),
                        variant: "success",
                        autoHideDelay: 5000,
                    });
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs["frm-Comentario"].hide();
                    this.$refs["datatable-Comentario"].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(this.$t("messages.problemSave"), {
                        title: "Error",
                        variant: "danger",
                        autoHideDelay: 5000,
                    });
                });
        },

        newMagnum(magnum, cantidad, index) {
            this.Magnum = {
                Cantidad: 0,
            };
            if (magnum && (index || index == 0)) {
                this.Magnum.id = magnum;
                this.Magnum.index = index;
            }
            if (cantidad && (index || index == 0)) {
                this.Magnum.Cantidad = this.getCantidad(cantidad);
            }
            this.$refs["frm-Magnum"].show();
            //this.$refs['frm-PersonalizarFormula'].hide();
        },
        cancelMagnum() {
            this.$refs["frm-Magnum"].hide();
            this.$refs["frm-PersonalizarFormula"].show();
        },
        saveMagnum() {
            this.errorBag = {};
            if (!this.Magnum.id || this.Magnum.Cantidad <= 0) {
                if (!this.Magnum.id) {
                    this.errorBag.Magnum = this.$t("messages.magnumRequired");
                }
                if (this.Magnum.Cantidad <= 0) {
                    this.errorBag.Cantidad = this.$t("messages.quantityRequired");
                }
                this.$forceUpdate();
            } else {
                if (this.Magnum.index || this.Magnum.index == 0) {
                    let index = this.Magnum.index;
                    this.Pantone.formula_magnum[index].Magnum = this.Magnum.id;
                    this.Pantone.formula_magnum[index].magnum.Magnum =
                        this.myOptionsMagnum[this.Magnum.id - 1].magnum;
                    this.Pantone.formula_magnum[index].magnum.ColorHex =
                        this.myOptionsMagnum[this.Magnum.id - 1].hex;
                    this.Pantone.formula_magnum[index].Cantidad = this.divideCantidad(
                        this.Magnum.Cantidad
                    );
                } else {
                    let aux = {
                        Magnum: 0,
                        magnum: {
                            Magnum: "",
                        },
                        Cantidad: 0,
                    };
                    aux.Magnum = this.Magnum.id;
                    aux.magnum.id = this.Magnum.id;
                    aux.magnum.Magnum = this.myOptionsMagnum[this.Magnum.id - 1].magnum;
                    aux.magnum.ColorHex = this.myOptionsMagnum[this.Magnum.id - 1].hex;
                    aux.Cantidad = this.divideCantidad(this.Magnum.Cantidad);
                    this.Pantone.formula_magnum.push(aux);
                }
                this.$refs["frm-Magnum"].hide();
                this.$refs["frm-PersonalizarFormula"].show();
            }
        },
        divideCantidad(cantidad) {
            let auxCantidad = cantidad;
            if (auxCantidad > 0) {
                if (this.value.Unit == "g") {
                    auxCantidad = auxCantidad / this.value.Cantidad / 10;
                } else {
                    auxCantidad = (auxCantidad / this.value.Cantidad) * 100;
                }
            }
            return auxCantidad;
        },
        deleteMagnum(index) {
            this.Pantone.formula_magnum.splice(index, 1);
        },

        newColor() {
            this.picker = null;
            this.$refs["frm-Color"].show();
            this.$refs["frm-PersonalizarFormula"].hide();
        },
        cancelColor() {
            this.$refs["frm-Color"].hide();
            this.$refs["frm-PersonalizarFormula"].show();
        },
        saveColor() {
            //TODO:
        },

        onSlideStart() {
            this.sliding = true;
        },
        onSlideEnd() {
            this.sliding = false;
        },
        checkForm: function(e) {
            e.preventDefault();
        },
        getBrightnessValue(hex) {
            return getBrightness(hex) > 0.6 ? "black" : "white";
        },
        changeValueCheckBox() {
            if (this.blanco) {
                this.colors = this.coloresBlanco;
            } else {
                this.colors = this.coloresNegro;
            }
        },
        pdfFormula(tipo, pantone, blanco) {
            if (this.pantone === null) {
                this.$swal({
                    icon: "warning",
                    title: this.$t("messages.warning"),
                    text: this.$t("messages.chooseValidPantone"),
                    showConfirmButton: false,
                    timer: 1500,
                });
                return;
            }
            let cantidadAux = this.getCantidadQuery();
            var urlFile =
                this.rutaApp +
                "api/PantonePDF?Pantone=" +
                pantone +
                "&Blanco=" +
                blanco +
                "&Fondo=" +
                false +
                "&TipoM=" +
                tipo +
                "&Lang=" +
                this.$i18n.locale +
                "&Tipo=" +
                this.value.Unit +
                "&Total=" +
                cantidadAux;
            window.open(urlFile);
        },
        myChangeEvent() {},
        mySelectEvent({ id, text }) {
            this.pantoneSelect = text;
        },
        getCantidadQuery() {
            if (this.value.Unit === "g") {
                return this.value.Cantidad * 1000;
            } else {
                return this.value.Cantidad;
            }
        },
        saveTodo() {
            //aqui final
            this.Pantone.Hexadecimal = this.color;
            this.Pantone.RGB = null;
            this.Pantone.Pantone = this.Pantone.Nombre;
            this.ss
                .storePantone(this.Pantone)
                .then((result) => {
                    //console.log("pantone resultado",result);
                    let response = result.data;
                    /* this.$bvToast.toast(                                   ) */
                    this.PantoneRespuesta = response.data;
                    console.log("PantoneID:", this.PantoneRespuesta.id);
                    //console.log("tamaño",this.arrayfuncion.length);
                    let total = 0;
                    let cantidad = 0;
                    for (let i = 0; i < this.Pantone.formula_magnum.length; i++) {
                        //cantidad=parseFloat(this.Pantone.formula_magnum[i].Cantidad).toFixed(2);
                        cantidad = parseFloat(this.Pantone.formula_magnum[i].Cantidad);
                        total = total + cantidad;
                    }
                    //console.log('total', total);

                    for (let i = 0; i < this.Pantone.formula_magnum.length; i++) {
                        //console.log(this.arrayfuncion[i]['Funcion']);
                        this.ss
                            .storeFormula({
                                Pantone: this.PantoneRespuesta.id,
                                Magnum: this.Pantone.formula_magnum[i]["Magnum"],
                                //'Cantidad':(parseFloat(this.Pantone.formula_magnum[i]['Cantidad'])/total)*100,
                                Cantidad: parseFloat(
                                    (parseFloat(this.Pantone.formula_magnum[i]["Cantidad"]) /
                                        total) *
                                    100
                                ).toFixed(2),
                                Blanco: this.blanco,
                                Original: false, //ojo aqui
                                Persona: this.auth.id,
                            })
                            .then((result) => {
                                // console.log("magnum resultado",i , result);
                                let response = result.data;
                                console.log(response.data.Fecha);
                            })
                            .catch((error) => {
                                this.errorBag = error.response.data.errors;
                                this.$bvToast.toast(this.$t("messages.problemSave"), {
                                    title: "Error",
                                    variant: "danger",
                                    autoHideDelay: 5000,
                                });
                            });
                    }
                    // window.location.reload();
                    this.$bvToast.toast(this.$t("messages.messageCreateFormula"), {
                        title: this.$t("messages.saveColorSuccessUpperCase"),
                        variant: "success",
                        autoHideDelay: 7000,
                    });
                    this.$refs["frm-PersonalizarFormula"].hide();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(this.$t("messages.problemSave"), {
                        title: "Error",
                        variant: "danger",
                        autoHideDelay: 5000,
                    });
                });
        },
        separarColores(colores) {
            this.coloresBlanco = cloneDeep(colores);
            this.coloresNegro = cloneDeep(colores);
            if (colores) {
                for (let i = 0; i < colores.length; i++) {
                    let auxBlanco = [];
                    let auxNegro = [];
                    if (colores[i].formula_magnum) {
                        for (let j = 0; j < colores[i].formula_magnum.length; j++) {
                            let value = colores[i].formula_magnum[j];
                            if (value.Blanco) {
                                auxBlanco.push(value);
                            } else {
                                auxNegro.push(value);
                            }
                        }
                    }
                    this.updateDataBlanco(auxBlanco, i);
                    this.updateDataNegro(auxNegro, i);
                }
            }
            this.changeValueCheckBox();
        },
        updateDataBlanco(colores, index) {
            this.coloresBlanco[index].formula_magnum = [];
            this.coloresBlanco[index].formula_magnum = colores;
        },
        updateDataNegro(colores, index) {
            this.coloresNegro[index].formula_magnum = [];
            this.coloresNegro[index].formula_magnum = colores;
        },
        getFormula() {
            this.errorBag = {};
            if (this.pantoneSelect === "" || this.value.Cantidad <= 0) {
                if (this.pantoneSelect === "") {
                    this.errorBag.pantoneSelect = this.$t("messages.codeColorRequired");
                }
                if (this.value.Cantidad <= 0) {
                    this.errorBag.Cantidad = this.$t("messages.quantityRequired");
                }
                this.$forceUpdate();
            } else {
                this.isLoading = true;
                this.ss
                    .getHistorial(this.pantoneSelect)
                    .then((result) => {
                        let response = result.data;
                        localStorage.setItem("current_historial", JSON.stringify(response));
                        this.separarColores(response);
                        this.isLoading = false;
                        this.$refs.mycarousel.goSlide(2);
                    })
                    .catch((error) => {
                        console.log(error);
                        this.isLoading = false;
                    });
            }
        },
        getVersion() {
            this.ss
                .getVersion()
                .then((result) => {
                    let response = result.data;
                    var pjson = require("../../../../package.json");
                    var local = response.version;
                    if (pjson.version !== local) {
                        clearCache(true);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        testToken() {
            this.ss
                .testToken()
                .then((result) => {})
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            this.storage.cleanSession();
                            this.$router.push("/Login");
                        }
                    }
                });
        },
        getCurrentHistorial() {
            let response = JSON.parse(localStorage.getItem("current_historial"));
            this.separarColores(response);
        },
        handler: function handler() {
            localStorage.setItem("current_historial", null);
        },
    },
    watch: {
        pantoneSelect(q) {},
        selectedPantoneHit(persona) {
            console.log(persona);
        },
        value: {
            handler(val) {
                if (val.Cantidad < 0) {
                    val.Cantidad = 1;
                }

                console.log("Esta es la cantidad ", val.Cantidad)
            },
            deep: true,
        },
        Magnum: {
            handler(val) {
                if (val.Cantidad > 0) {
                    val.Cantidad = val.Cantidad / 1;
                }
            },
            deep: true,
        },
    },
    components: {
        Carousel3d,
        Slide,
        PulseLoader,
        VSwatches,
        showAt,
        VueEditor,
    },
    mounted() {
        if (
            process.env.VUE_APP_ENVIRONMENT === "PRODUCTION" &&
            location.protocol !== "https:"
        ) {
            location.replace(
                `https:${location.href.substring(location.protocol.length)}`
            );
        }
        this.getVersion();
        this.auth = JSON.parse(localStorage.getItem("persona"));
        if (!this.auth) {
            this.storage.cleanSession();
            this.$router.push("/Login");
        } else {
            this.getCurrentHistorial();
            this.testToken();
            this.listPantoneOriginal();
        }
    },
};