var axios = require("axios");

export default class MainService {
  constructor() {
    var token = JSON.parse(localStorage.getItem("token"));
    axios.defaults.baseURL = process.env.VUE_APP_MAIN_SERVICE;
    if (token) {
      axios.defaults.headers.common = {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      };
    } else {
      axios.defaults.headers.common = {
        "Content-Type": "application/json",
        Accept: "application/json",
      };
    }
  }

  getToken() {
    return axios.defaults.headers.common;
  }

  login(item) {
    return axios.post("api/login", item);
  }

  resetPassword(item) {
    return axios.post("api/Persona/resetPassword", item);
  }

  storeExterno(item) {
    return axios.post("api/Persona/storeExterno", item);
  }

  uploadFile(item) {
    return axios.post("api/uploadFile", item);
  }

  loginToken365(item) {
    return axios.post("api/loginToken365", item);
  }

  getVersion() {
    return axios.get("api/getVersion");
  }

  testToken() {
    return axios.get("api/Persona/testToken");
  }

  indexPersona() {
    return axios.defaults.baseURL + "api/Persona/index";
  }

  showPersona(id) {
    return axios.get("api/Persona/show?id=" + id);
  }

  listPersona() {
    return axios.get("api/Persona/list");
  }

  storePersona(item) {
    return axios.post("api/Persona/store", item);
  }

  correoPersona(item) {
    return axios.post("api/vista", item);
  }

  destroyPersona(item) {
    return axios.post("api/Persona/destroy", item);
  }

  changePassword(item) {
    return axios.post("api/Persona/changePassword", item);
  }
  socialLogin(item) {
    return axios.post("api/Persona/storeSocial", item);
  }

  //---------------------Entidad

  showEntidad(id) {
    return axios.get("api/Entidad/show?id=" + id);
  }

  indexEntidad() {
    return axios.defaults.baseURL + "api/Entidad/index";
  }

  listEntidad() {
    return axios.get("api/Entidad/list");
  }

  storeEntidad(item) {
    return axios.post("api/Entidad/store", item);
  }

  destroyEntidad(item) {
    return axios.post("api/Entidad/destroy", item);
  }

  showRol(id) {
    return axios.get("api/Rol/show?id=" + id);
  }

  indexRol() {
    return axios.defaults.baseURL + "api/Rol/index";
  }

  listRol() {
    return axios.get("api/Rol/list");
  }

  storeRol(item) {
    return axios.post("api/Rol/store", item);
  }

  destroyRol(item) {
    return axios.post("api/Rol/destroy", item);
  }

  //TipoReporte
  showTipoReporte(id) {
    return axios.get("api/TipoReporte/show?id=" + id);
  }

  indexTipoReporte() {
    return axios.defaults.baseURL + "api/TipoReporte/index";
  }

  listTipoReporte() {
    return axios.get("api/TipoReporte/list");
  }

  storeTipoReporte(item) {
    return axios.post("api/TipoReporte/store", item);
  }

  destroyTipoReporte(item) {
    return axios.post("api/TipoReporte/destroy", item);
  }

  generateTipoReporte(item) {
    return axios.post("api/TipoReporte/generate", item);
  }

  generaReporte(x, y) {
    return axios.get("api/Generalx?x=" + x + "&y=" + y);
  }

  /***Pantone */

  showPantone(id) {
    return axios.get("api/Pantone/show?id=" + id);
  }

  indexPantone() {
    return axios.defaults.baseURL + "api/Pantone/index";
  }

  listPantone() {
    return axios.get("api/Pantone/list");
  }

  listPantoneOriginal() {
    return axios.get("api/Pantone/listOriginal");
  }

  listServicioPersonal(item) {
    return axios.get("api/ServicioPersonal/list", item);
  }

  storePantone(item) {
    return axios.post("api/Pantone/store", item);
  }

  destroyPantone(item) {
    return axios.post("api/Pantone/destroy", item);
  }

  select2Pantone(item) {
    return axios.get("api/Pantone/select2?q=" + item);
  }

  getHistorial(item) {
    if (item) {
      // var str = item.split(" ");
      // return axios.get("api/Pantone/historialv2?Pantone=" + str[0]);
      return axios.get("api/Pantone/historialv2?Pantone=" + item);
    } else {
      return axios.get("api/Pantone/historialv2?Pantone=" + item);
    }
  }

  /***Magnum */

  showMagnum(id) {
    return axios.get("api/Magnum/show?id=" + id);
  }

  indexMagnum() {
    return axios.defaults.baseURL + "api/Magnum/index";
  }

  listMagnum() {
    return axios.get("api/Magnum/list");
  }

  storeMagnum(item) {
    return axios.post("api/Magnum/store", item);
  }

  destroyMagnum(item) {
    return axios.post("api/Magnum/destroy", item);
  }

  /***Formula */
  pdfFormula(id) {
    return axios.get("api/Formula/PantonePDF", id);
  }

  showFormula(id) {
    return axios.get("api/Formula/show", id);
  }

  indexFormula() {
    return axios.defaults.baseURL + "api/Formula/index";
  }

  listFormula() {
    return axios.get("api/Formula/list");
  }

  listPersonaFormula(persona) {
    return axios.get("api/Formula/listPersona?Persona=" + persona);
  }

  storeFormula(item) {
    return axios.post("api/Formula/store", item);
  }

  destroyFormula(item) {
    return axios.post("api/Formula/destroy", item);
  }

  /***Noticia */

  showNoticia(id) {
    return axios.get("api/Noticia/show?id=" + id);
  }

  indexNoticia() {
    return axios.defaults.baseURL + "api/Noticia/index";
  }

  listNoticia() {
    return axios.get("api/Noticia/list");
  }

  storeNoticia(item) {
    return axios.post("api/Noticia/store", item);
  }

  destroyNoticia(item) {
    return axios.post("api/Noticia/destroy", item);
  }

  /***Comentarios */

  showComentario(id) {
    return axios.get("api/Comentario/show?id=" + id);
  }

  indexComentario() {
    return axios.defaults.baseURL + "api/Comentario/index";
  }

  listComentario(persona) {
    return axios.get("api/Comentario/list?Persona=" + persona);
  }

  storeComentario(item) {
    return axios.post("api/Comentario/store", item);
  }

  destroyComentario(item) {
    return axios.post("api/Comentario/destroy", item);
  }
}
