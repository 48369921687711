import MainService from "@/services/MainService.js";
import PageOptions from '../../../config/PageOptions.vue'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

export default {
    name: "NotificacionesPage",
    data() {
        let ss = new MainService();
        return {
            msg: "NotificacionesPage",
            ss: ss,
            auth: {},
            slide: 0,
            sliding: null,
            noticias: [],
            model: null,
            loading: true,
            color: '#8753de',
            size: '15px',
            margin: '2px',
            radius: '100%',
        };
    },
    created() {
        PageOptions.pageWithMegaMenu = true;
        PageOptions.pageWithoutSidebar = true;
        if (this.auth.Rol == 5) {
            PageOptions.pageWithTopMenu = true;
        }
        //document.body.className = 'bg-white';
    },
    methods: {
        onSlideStart() {
            this.sliding = true
        },
        onSlideEnd() {
            this.sliding = false
        },
        goUrl(url) {
            window.open(url);
        },
        getNoticias() {
            this.loading = true;
            this.ss.listNoticia().then(
                (result) => {
                    let response = result.data;
                    this.noticias = response.data;
                    this.loading = false;
                }
            ).catch(error => {
                console.log(error);
                this.loading = false;
            });
        }
    },
    components: {
        PulseLoader
    },
    mounted() {
        if (process.env.VUE_APP_ENVIRONMENT === 'PRODUCTION' && location.protocol !== 'https:') {
            location.replace(`https:${location.href.substring(location.protocol.length)}`);
        }
        this.auth = JSON.parse(localStorage.getItem('persona'));
        if (!this.auth) {
            this.$router.push('/Login');
        } else {
            this.getNoticias();
        }
    }
};