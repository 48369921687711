import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
import Constants from "@/data/Constants.js";
import cloneDeep from "lodash.clonedeep";
import VSwatches from "vue-swatches";
import "vue-swatches/dist/vue-swatches.css";
window.$ = window.jQuery = require("jquery");

export default {
  name: "PantonePage",
  data() {
    let ss = new MainService();
    let constants = new Constants();
    return {
      msg: "PantonePage",
      ss: ss,
      ajax: {
        url: ss.indexPantone(),
        headers: ss.getToken(),
      },
      columns: [
        {
          data: "id",
          name: "p.id",
          orderable: false,
          searchable: false,
          visible: false,
        },
        {
          data: "DT_RowIndex",
          name: "DT_RowIndex",
          title: "N",
          orderable: false,
          searchable: false,
        },
        { data: "Pantone", name: "p.Pantone", title: "Pantone" },
        { data: "Orden", name: "p.Orden", title: "Orden" },
        //{ data: "RGB", name: "p.RGB", title: "RGB" },
        {
          data: "RGB",
          searchable: false,
          name: "p.RGB",
          title: "Pantone Color",
          render: function (data, type, row) {
            if (row.RGB) {
              return (
                '<div style="background-color:rgb(' +
                row.RGB +
                ');"> </br></br></div>'
              );
            } else {
              return '<div style="background-color:red;"> </br>ERROR COLOR</br></div>';
            }
          },
        },
        //{ data: 'ColorHex', name: 'ColorHex', title: 'Hexadecimal'},
        {
          data: "Original",
          name: "ff.Original",
          title: "Estado",
          className: "centradito",
          render: function (data, type, row) {
            if (row.Original) {
              return "ORIGINAL";
            } else {
              return "EXTERIOR";
            }
          },
        },
        {
          data: "Activo",
          name: "p.Activo",
          title: "Estado",
          className: "centradito",
          render: function (data, type, row) {
            if (!row.Activo) {
              return '<i class="fa fa-ban text-danger"></i>';
            } else {
              return '<i class="fa fa-check text-success"></i>';
            }
          },
        },
        {
          data: "action",
          orderable: false,
          title: "Acciones",
          searchable: false,
        },
      ],
      pantones: [],
      rutaApp: process.env.VUE_APP_MAIN_SERVICE,
      pantone: {},
      blanco: false,
      isLoading: false,
      original: true,
      color: "#BDC3C8",
      eliminados: [],
      myOptionsMagnum: constants.getListMagnum(),
      Magnum: {
        Cantidad: 0,
      },
      value: {
        Unit: "gr",
        Cantidad: 1,
      },
      Pantone: {
        formula_magnum: [],
      },
      errorBag: {},
      colorFields: [
        "Nro",
        { key: "magnum.Magnum", label: "Magnum", sortable: true },
        { key: "Color", label: "Color", class: "centradito" },
        {
          key: "Cantidad",
          label: "Cantidad",
          sortable: true,
          class: "centradito",
        },
        /*{key: 'cantidad',label: 'Cantidad',variant: 'secondary',sortable: true,class:'centradito'}, */
      ],
    };
  },
  methods: {
    checkForm: function (e) {
      e.preventDefault();
      this.saveTodo();
    },
    isEmpty(data) {
      return !data || data === "";
    },
    changeValueCheckBox() {
      console.log("entraaa");
      //this.blanco ? this.colors = this.coloresBlanco : this.colors = this.coloresNegro;
    },
    newPantone() {
      this.pantone = { formula_magnum: [] };
      this.Pantone = { formula_magnum: [] };
      this.original = true;
      this.eliminados = [];
      this.$refs["frm-pantone"].show();
    },
    showPantone(id) {
      //console.log(id, 'pantoe');
      this.isLoading = true;
      this.eliminados = [];
      this.ss
        .showPantone(id)
        .then((result) => {
          let response = result.data;
          console.log(response.data, "rrees");
          this.pantone = response.data;
          this.$refs["view-pantone"].show();
          this.isLoading = false;

          for (let i = 0; i < this.pantone.formula_magnum.length; i++) {
            if (
              this.pantone.formula_magnum[i].Original !== null &&
              this.pantone.formula_magnum[i].Original !== undefined
            ) {
              this.original = this.pantone.formula_magnum[i].Original;
              break;
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    changeBlanco(index) {
      this.blanco = index === 0;
    },
    pdfFormula(pantone, blanco) {
      if (this.pantone.id == null) {
        this.$swal({
          icon: "warning",
          title: "Atencion!",
          text: "Debe elegir un Pantone valido!",
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      }
      var urlFile =
        this.rutaApp +
        "api/PantonePDF?Pantone=" +
        pantone +
        "&Blanco=" +
        blanco +
        "&Lang=" +
        this.$i18n.locale;
      window.open(urlFile);
    },
    convertPantone() {
      let auxColor = cloneDeep(this.pantone);
      auxColor.Nombre = auxColor.Pantone;
      this.color = auxColor.ColorHex;
      if (this.blanco) {
        auxColor.formula_magnum = auxColor.blanco_magnum;
      } else {
        auxColor.formula_magnum = auxColor.negro_magnum;
      }
      this.Pantone = auxColor;
    },
    editPantone(pantone) {
      if (pantone) {
        this.convertPantone();
      }
      this.$refs["frm-pantone"].show();
      this.$refs["view-pantone"].hide();
    },
    cancelPantone() {
      if (this.pantone.id) {
        this.$refs["view-pantone"].show();
      }
      this.$refs["frm-pantone"].hide();
    },
    savePantone() {
      this.ss
        .storePantone(this.pantone)
        .then((result) => {
          console.log(result);
          let response = result.data;
          this.$bvToast.toast(response.msg, {
            title: this.$t("messages.success"),
            variant: "success",
            autoHideDelay: 5000,
          });
          //this.$refs['view-consulta'].show(); //para volver al modal
          console.log(response);
          this.$refs["frm-pantone"].hide();
          this.$refs["datatable-pantone"].reload();
        })
        .catch((error) => {
          this.errorBag = error.response.data.errors;
          this.$bvToast.toast(this.$t("messages.problemSave"), {
            title: "Error",
            variant: "danger",
            autoHideDelay: 5000,
          });
        });
    },
    deletePantone() {
      this.$swal({
        title: "Estas seguro que deseas eliminar?",
        text: "Esta accion es irreversible!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete.isConfirmed) {
          this.ss
            .destroyPantone(this.pantone)
            .then((result) => {
              let response = result.data;
              console.log(response);
              this.$bvToast.toast(response.msg, {
                title: this.$t("messages.success"),
                variant: "success",
                autoHideDelay: 5000,
              });
              this.$refs["view-pantone"].hide();
              this.$refs["datatable-pantone"].reload();
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          //swal("Your imaginary file is safe!");
        }
      });
    },
    getCantidad(cantidad) {
      let cantidadInput;
      if (this.value.Cantidad) {
        cantidadInput = parseInt(this.value.Cantidad);
        if (this.value.Unit == "gr") {
          cantidadInput = cantidadInput * 1000;
        }
        if (cantidadInput < 10) {
          return ((parseFloat(cantidad) * cantidadInput) / 100).toFixed(3);
        }
        return ((parseFloat(cantidad) * cantidadInput) / 100).toFixed(2);
      } else {
        return parseFloat(cantidad).toFixed(2);
      }
    },
    divideCantidad(cantidad) {
      let auxCantidad = cantidad;
      if (auxCantidad > 0) {
        if (this.value.Unit == "gr") {
          auxCantidad = parseFloat(
            auxCantidad / this.value.Cantidad / 10
          ).toFixed(3);
        } else {
          auxCantidad = (auxCantidad / this.value.Cantidad) * 100;
        }
      }
      return auxCantidad;
    },
    newMagnum(magnum, cantidad, index) {
      this.Magnum = {
        Cantidad: 0,
      };
      this.errorBag = {};
      if (magnum && (index || index == 0)) {
        this.Magnum.id = magnum;
        this.Magnum.index = index;
      }
      if (cantidad && (index || index == 0)) {
        this.Magnum.Cantidad = this.getCantidad(cantidad);
      }
      this.$refs["frm-Magnum"].show();
      this.$refs["frm-pantone"].hide();
    },
    cancelMagnum() {
      this.$refs["frm-Magnum"].hide();
      this.$refs["frm-pantone"].show();
    },
    saveMagnum() {
      this.errorBag = {};
      if (!this.Magnum.id || this.Magnum.Cantidad <= 0) {
        if (!this.Magnum.id) {
          this.errorBag.Magnum = this.$t("messages.magnumRequired");
        }
        if (this.Magnum.Cantidad <= 0) {
          this.errorBag.Cantidad = this.$t("messages.quantityRequired");
        }
        this.$forceUpdate();
      } else {
        if (this.Magnum.index || this.Magnum.index == 0) {
          console.log("entra para aca");
          let index = this.Magnum.index;
          this.Pantone.formula_magnum[index].idFormula =
            this.Pantone.formula_magnum[index].id;
          this.Pantone.formula_magnum[index].Magnum = this.Magnum.id;
          this.Pantone.formula_magnum[index].magnum.Magnum =
            this.myOptionsMagnum[this.Magnum.id - 1].magnum;
          this.Pantone.formula_magnum[index].magnum.ColorHex =
            this.myOptionsMagnum[this.Magnum.id - 1].hex;
          this.Pantone.formula_magnum[index].Cantidad = this.divideCantidad(
            this.Magnum.Cantidad
          );
        } else {
          console.log("o entra para aca");
          let aux = {
            Magnum: 0,
            magnum: {
              Magnum: "",
            },
            Cantidad: 0,
          };
          aux.Magnum = this.Magnum.id;
          aux.idFormula = null;
          aux.magnum.id = this.Magnum.id;
          aux.magnum.Magnum = this.myOptionsMagnum[this.Magnum.id - 1].magnum;
          aux.magnum.ColorHex = this.myOptionsMagnum[this.Magnum.id - 1].hex;
          aux.Cantidad = this.divideCantidad(this.Magnum.Cantidad);
          this.Pantone.formula_magnum.push(aux);
        }
        this.$refs["frm-Magnum"].hide();
        this.$refs["frm-pantone"].show();
      }
    },

    deleteMagnum(index) {
      if (this.Pantone.formula_magnum[index].id) {
        this.eliminados.push(this.Pantone.formula_magnum[index].id);
      }
      this.Pantone.formula_magnum.splice(index, 1);
    },

    saveTodo() {
      //aqui final
      if (
        this.isEmpty(this.Pantone.Nombre) ||
        this.Pantone.formula_magnum.length === 0
      ) {
        this.$bvToast.toast(this.$t("messages.nameFormulaRequired"), {
          title: "Error",
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      }

      let alguiyo = 2;
      if (alguiyo == 2) {
        if (this.original) {
          this.Pantone.Original = this.original;
        }
        this.Pantone.Hexadecimal = this.color;
        this.Pantone.RGB = null;
        this.Pantone.eliminados = this.eliminados;
        this.Pantone.Pantone = this.Pantone.Nombre;
        this.Pantone.Activo = true;
        this.ss
          .storePantone(this.Pantone)
          .then((result) => {
            let response = result.data;
            this.PantoneRespuesta = response.data;
            console.log("PantoneID:", this.PantoneRespuesta.id);

            let cantidad = 0;
            let total = 0;
            for (let i = 0; i < this.Pantone.formula_magnum.length; i++) {
              cantidad = parseFloat(this.Pantone.formula_magnum[i].Cantidad);
              total = total + cantidad;
            }

            for (let i = 0; i < this.Pantone.formula_magnum.length; i++) {
              this.ss
                .storeFormula({
                  Pantone: this.PantoneRespuesta.id,
                  Magnum: this.Pantone.formula_magnum[i]["Magnum"],
                  id: this.Pantone.formula_magnum[i]["id"]
                    ? this.Pantone.formula_magnum[i]["id"]
                    : null,
                  Cantidad: parseFloat(
                    (parseFloat(this.Pantone.formula_magnum[i]["Cantidad"]) /
                      total) *
                      100
                  ).toFixed(3),
                  Blanco: this.blanco,
                  Original: this.original, //ojo aqui
                  Persona: 1,
                })
                .then((result) => {
                  let response = result.data;
                  console.log(response.data.Fecha);
                })
                .catch((error) => {
                  this.errorBag = error.response.data.errors;
                  this.$bvToast.toast(this.$t("messages.problemSave"), {
                    title: "Error",
                    variant: "danger",
                    autoHideDelay: 5000,
                  });
                });
            }

            // console.log('viene aca>>>>>>>>>>>')
            this.$bvToast.toast(this.$t("messages.saveColorSuccessUpperCase"), {
              title: this.$t("messages.success"),
              variant: "success",
            });

            //window.location.reload();
            this.$refs["frm-pantone"].hide();
          })
          .catch((error) => {
            console.log("error>>>>>", error);
            //this.errorBag = error.response.data.errors;
            this.$bvToast.toast(this.$t("messages.problemSave"), {
              title: "Error",
              variant: "danger",
              autoHideDelay: 5000,
            });
          });
      }
    },

    newColor() {
      this.color = "#BDC3C8";
      this.$refs["frm-Color"].show();
      this.$refs["frm-pantone"].hide();
    },
    draw() {
      window.$(".btn-datatable-Pantone").on("click", (evt) => {
        const data = window.$(evt.target)[0].id;
        this.showPantone(data);
      });
    },
  },
  components: {
    dataTable,
    VSwatches,
    Loader,
  },
  mounted() {
    var persona = JSON.parse(localStorage.getItem("persona"));
    if (!persona) {
      this.$router.push("/Admin/Login");
    } else {
      if (persona.Rol !== 1) {
        this.$router.push("/Admin/Login");
      }
    }
  },
};
