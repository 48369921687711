import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
  name: "ComentarioPage",
  data() {
    let ss = new MainService();
    return {
      msg: "ComentarioPage",
      ss: ss,
      ajax: {
        url: ss.indexComentario(),
        headers: ss.getToken(),
      },
      columns: [
        {
          data: "id",
          name: "id",
          orderable: false,
          searchable: false,
          visible: false,
        },
        {
          data: "DT_RowIndex",
          name: "DT_RowIndex",
          title: "N",
          orderable: false,
          searchable: false,
        },
        { data: "Comentario", name: "Comentario", title: "Título" },
        { data: "Html", name: "Html", title: "Comentario" },
        { data: "Fecha", name: "Fecha", title: "Fecha" },
        { data: "Activo", name: "Activo", title: "Activo" },
        {
          data: "action",
          orderable: false,
          title: "Acciones",
          searchable: false,
        },
      ],
      Comentarios: [],
      Comentario: {},
      isLoading: false,
      errorBag: {},
    };
  },
  methods: {
    newComentario() {
      this.Comentario = {};
      this.$refs["frm-Comentario"].show();
    },
    showComentario(id) {
      this.isLoading = true;
      this.ss
        .showComentario(id)
        .then((result) => {
          let response = result.data;
          this.Comentario = response.data;
          this.$refs["view-Comentario"].show();
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    editComentario() {
      this.$refs["frm-Comentario"].show();
      this.$refs["view-Comentario"].hide();
    },
    cancelComentario() {
      if (this.Comentario.id) {
        this.$refs["view-Comentario"].show();
      }
      this.$refs["frm-Comentario"].hide();
    },
    saveComentario() {
      this.ss
        .storeComentario(this.Comentario)
        .then((result) => {
          console.log(result);
          let response = result.data;
          this.$bvToast.toast(response.msg, {
            title: this.$t("messages.success"),
            variant: "success",
            autoHideDelay: 5000,
          });
          //this.$refs['view-consulta'].show(); //para volver al modal
          console.log(response);
          this.$refs["frm-Comentario"].hide();
          this.$refs["datatable-Comentario"].reload();
        })
        .catch((error) => {
          this.errorBag = error.response.data.errors;
          this.$bvToast.toast(this.$t("messages.problemSave"), {
            title: "Error",
            variant: "danger",
            autoHideDelay: 5000,
          });
        });
    },
    deleteComentario() {
      this.$swal({
        title: "Estas seguro que deseas eliminar?",
        text: "Esta accion es irreversible!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete.isConfirmed) {
          this.ss
            .destroyComentario(this.Comentario)
            .then((result) => {
              let response = result.data;
              console.log(response);
              this.$bvToast.toast(response.msg, {
                title: this.$t("messages.success"),
                variant: "success",
                autoHideDelay: 5000,
              });
              this.$refs["view-Comentario"].hide();
              this.$refs["datatable-Comentario"].reload();
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          //swal("Your imaginary file is safe!");
        }
      });
    },
    draw() {
      window.$(".btn-datatable-Comentario").on("click", (evt) => {
        const data = window.$(evt.target)[0].id;
        this.showComentario(data);
      });
    },
  },
  components: {
    dataTable,
    Loader,
  },
  mounted() {
    var persona = JSON.parse(localStorage.getItem("persona"));
    if (!persona) {
      this.$router.push("/Admin/Login");
    } else {
      if (persona.Rol !== 1) {
        this.$router.push("/Admin/Login");
      }
    }
  },
};
