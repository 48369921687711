import MainService from "@/services/MainService.js";

export default {
  name: "PrincipalPage",
  data() {
    let ss = new MainService();

    function handleGetDate(minusDate) {
      var d = new Date();
      d = d.setDate(d.getDate() - minusDate);
      return d;
    }
    let today = new Date();

    var eventsData = [
      {
        date: `${today.getFullYear()}/${today.getMonth() + 1}/15`,
        title: "Sales Reporting",
        badge: "bg-teal",
        time: "9:00am",
      },
      {
        date: `${today.getFullYear()}/${today.getMonth() + 1}/24`,
        title: "Have a meeting with sales team",
        badge: "bg-blue",
        time: "2:45pm",
      },
    ];

    return {
      msg: "PrincipalPage",
      ss: ss,
      ajax: {
        url: ss.indexPersona(),
        headers: ss.getToken(),
      },

      areaChart: {
        rightAlignYAxis: false,
        showControls: false,
        clipEdge: false,
        data: [
          {
            key: "Unique Visitors",
            color: "#5AC8FA",
            values: [
              { x: handleGetDate(77), y: 13 },
              { x: handleGetDate(76), y: 13 },
              { x: handleGetDate(75), y: 6 },
              { x: handleGetDate(73), y: 6 },
              { x: handleGetDate(72), y: 6 },
              { x: handleGetDate(71), y: 5 },
              { x: handleGetDate(70), y: 5 },
              { x: handleGetDate(69), y: 5 },
              { x: handleGetDate(68), y: 6 },
              { x: handleGetDate(67), y: 7 },
              { x: handleGetDate(66), y: 6 },
              { x: handleGetDate(65), y: 9 },
              { x: handleGetDate(64), y: 9 },
              { x: handleGetDate(63), y: 8 },
              { x: handleGetDate(62), y: 10 },
              { x: handleGetDate(61), y: 10 },
              { x: handleGetDate(60), y: 10 },
              { x: handleGetDate(59), y: 10 },
              { x: handleGetDate(58), y: 9 },
              { x: handleGetDate(57), y: 9 },
              { x: handleGetDate(56), y: 10 },
              { x: handleGetDate(55), y: 9 },
              { x: handleGetDate(54), y: 9 },
              { x: handleGetDate(53), y: 8 },
              { x: handleGetDate(52), y: 8 },
              { x: handleGetDate(51), y: 8 },
              { x: handleGetDate(50), y: 8 },
              { x: handleGetDate(49), y: 8 },
              { x: handleGetDate(48), y: 7 },
              { x: handleGetDate(47), y: 7 },
              { x: handleGetDate(46), y: 6 },
              { x: handleGetDate(45), y: 6 },
              { x: handleGetDate(44), y: 6 },
              { x: handleGetDate(43), y: 6 },
              { x: handleGetDate(42), y: 5 },
              { x: handleGetDate(41), y: 5 },
              { x: handleGetDate(40), y: 4 },
              { x: handleGetDate(39), y: 4 },
              { x: handleGetDate(38), y: 5 },
              { x: handleGetDate(37), y: 5 },
              { x: handleGetDate(36), y: 5 },
              { x: handleGetDate(35), y: 7 },
              { x: handleGetDate(34), y: 7 },
              { x: handleGetDate(33), y: 7 },
              { x: handleGetDate(32), y: 10 },
              { x: handleGetDate(31), y: 9 },
              { x: handleGetDate(30), y: 9 },
              { x: handleGetDate(29), y: 10 },
              { x: handleGetDate(28), y: 11 },
              { x: handleGetDate(27), y: 11 },
              { x: handleGetDate(26), y: 8 },
              { x: handleGetDate(25), y: 8 },
              { x: handleGetDate(24), y: 7 },
              { x: handleGetDate(23), y: 8 },
              { x: handleGetDate(22), y: 9 },
              { x: handleGetDate(21), y: 8 },
              { x: handleGetDate(20), y: 9 },
              { x: handleGetDate(19), y: 10 },
              { x: handleGetDate(18), y: 9 },
              { x: handleGetDate(17), y: 10 },
              { x: handleGetDate(16), y: 16 },
              { x: handleGetDate(15), y: 17 },
              { x: handleGetDate(14), y: 16 },
              { x: handleGetDate(13), y: 17 },
              { x: handleGetDate(12), y: 16 },
              { x: handleGetDate(11), y: 15 },
              { x: handleGetDate(10), y: 14 },
              { x: handleGetDate(9), y: 24 },
              { x: handleGetDate(8), y: 18 },
              { x: handleGetDate(7), y: 15 },
              { x: handleGetDate(6), y: 14 },
              { x: handleGetDate(5), y: 16 },
              { x: handleGetDate(4), y: 16 },
              { x: handleGetDate(3), y: 17 },
              { x: handleGetDate(2), y: 7 },
              { x: handleGetDate(1), y: 7 },
              { x: handleGetDate(0), y: 7 },
            ],
          },
          {
            key: "Page Views",
            color: "#348fe2",
            values: [
              { x: handleGetDate(77), y: 14 },
              { x: handleGetDate(76), y: 13 },
              { x: handleGetDate(75), y: 15 },
              { x: handleGetDate(73), y: 14 },
              { x: handleGetDate(72), y: 13 },
              { x: handleGetDate(71), y: 15 },
              { x: handleGetDate(70), y: 16 },
              { x: handleGetDate(69), y: 16 },
              { x: handleGetDate(68), y: 14 },
              { x: handleGetDate(67), y: 14 },
              { x: handleGetDate(66), y: 13 },
              { x: handleGetDate(65), y: 12 },
              { x: handleGetDate(64), y: 13 },
              { x: handleGetDate(63), y: 13 },
              { x: handleGetDate(62), y: 15 },
              { x: handleGetDate(61), y: 16 },
              { x: handleGetDate(60), y: 16 },
              { x: handleGetDate(59), y: 17 },
              { x: handleGetDate(58), y: 17 },
              { x: handleGetDate(57), y: 18 },
              { x: handleGetDate(56), y: 15 },
              { x: handleGetDate(55), y: 15 },
              { x: handleGetDate(54), y: 15 },
              { x: handleGetDate(53), y: 19 },
              { x: handleGetDate(52), y: 19 },
              { x: handleGetDate(51), y: 18 },
              { x: handleGetDate(50), y: 18 },
              { x: handleGetDate(49), y: 17 },
              { x: handleGetDate(48), y: 16 },
              { x: handleGetDate(47), y: 18 },
              { x: handleGetDate(46), y: 18 },
              { x: handleGetDate(45), y: 18 },
              { x: handleGetDate(44), y: 16 },
              { x: handleGetDate(43), y: 14 },
              { x: handleGetDate(42), y: 14 },
              { x: handleGetDate(41), y: 13 },
              { x: handleGetDate(40), y: 14 },
              { x: handleGetDate(39), y: 13 },
              { x: handleGetDate(38), y: 10 },
              { x: handleGetDate(37), y: 9 },
              { x: handleGetDate(36), y: 10 },
              { x: handleGetDate(35), y: 11 },
              { x: handleGetDate(34), y: 11 },
              { x: handleGetDate(33), y: 11 },
              { x: handleGetDate(32), y: 10 },
              { x: handleGetDate(31), y: 9 },
              { x: handleGetDate(30), y: 10 },
              { x: handleGetDate(29), y: 13 },
              { x: handleGetDate(28), y: 14 },
              { x: handleGetDate(27), y: 14 },
              { x: handleGetDate(26), y: 13 },
              { x: handleGetDate(25), y: 12 },
              { x: handleGetDate(24), y: 11 },
              { x: handleGetDate(23), y: 13 },
              { x: handleGetDate(22), y: 13 },
              { x: handleGetDate(21), y: 13 },
              { x: handleGetDate(20), y: 13 },
              { x: handleGetDate(19), y: 14 },
              { x: handleGetDate(18), y: 13 },
              { x: handleGetDate(17), y: 13 },
              { x: handleGetDate(16), y: 19 },
              { x: handleGetDate(15), y: 21 },
              { x: handleGetDate(14), y: 22 },
              { x: handleGetDate(13), y: 25 },
              { x: handleGetDate(12), y: 24 },
              { x: handleGetDate(11), y: 24 },
              { x: handleGetDate(10), y: 22 },
              { x: handleGetDate(9), y: 16 },
              { x: handleGetDate(8), y: 15 },
              { x: handleGetDate(7), y: 12 },
              { x: handleGetDate(6), y: 12 },
              { x: handleGetDate(5), y: 15 },
              { x: handleGetDate(4), y: 15 },
              { x: handleGetDate(3), y: 15 },
              { x: handleGetDate(2), y: 18 },
              { x: handleGetDate(2), y: 18 },
              { x: handleGetDate(0), y: 17 },
            ],
          },
        ],
      },
      donutChart: {
        donut: true,
        data: [
          {
            label: "Return Visitors",
            value: 784466,
            color: "#348fe2",
          },
          {
            label: "New Visitors",
            value: 416747,
            color: "#8753de",
          },
        ],
      },
      map: {
        styles: [
          {
            featureType: "all",
            elementType: "labels.text.fill",
            stylers: [{ saturation: 36 }, { lightness: 40 }],
          },
          {
            featureType: "all",
            elementType: "labels.text.stroke",
            stylers: [
              { visibility: "on" },
              { color: "#000000" },
              { lightness: 16 },
            ],
          },
          {
            featureType: "all",
            elementType: "labels.icon",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "administrative",
            elementType: "geometry.fill",
            stylers: [{ color: "#2d353c" }, { lightness: 20 }],
          },
          {
            featureType: "administrative",
            elementType: "geometry.stroke",
            stylers: [{ color: "#000000" }, { lightness: 17 }, { weight: 1.2 }],
          },
          {
            featureType: "administrative",
            elementType: "labels.text.fill",
            stylers: [{ color: "#d8d8d8" }],
          },
          {
            featureType: "administrative.neighborhood",
            elementType: "geometry.fill",
            stylers: [{ color: "#ff0000" }],
          },
          {
            featureType: "administrative.land_parcel",
            elementType: "geometry.fill",
            stylers: [{ color: "#2d353c" }],
          },
          {
            featureType: "landscape",
            elementType: "geometry",
            stylers: [{ color: "#000000" }, { lightness: 20 }],
          },
          {
            featureType: "landscape",
            elementType: "geometry.fill",
            stylers: [{ color: "#2d353c" }],
          },
          {
            featureType: "landscape",
            elementType: "labels.text.fill",
            stylers: [{ color: "#8753de" }],
          },
          {
            featureType: "landscape.man_made",
            elementType: "geometry.fill",
            stylers: [{ color: "#2d353c" }],
          },
          {
            featureType: "poi",
            elementType: "geometry",
            stylers: [{ color: "#000000" }, { lightness: 21 }],
          },
          {
            featureType: "poi",
            elementType: "geometry.fill",
            stylers: [{ color: "#2d353c" }],
          },
          {
            featureType: "poi",
            elementType: "labels.text.fill",
            stylers: [{ color: "#575d63" }],
          },
          {
            featureType: "road",
            elementType: "labels.text.fill",
            stylers: [{ color: "#348fe2" }],
          },
          {
            featureType: "road.highway",
            elementType: "geometry.fill",
            stylers: [{ color: "#000000" }, { lightness: 17 }],
          },
          {
            featureType: "road.highway",
            elementType: "geometry.stroke",
            stylers: [{ color: "#000000" }, { lightness: 29 }, { weight: 0.2 }],
          },
          {
            featureType: "road.highway.controlled_access",
            elementType: "geometry.fill",
            stylers: [{ color: "#575d63" }],
          },
          {
            featureType: "road.arterial",
            elementType: "geometry",
            stylers: [{ color: "#000000" }, { lightness: 18 }],
          },
          {
            featureType: "road.arterial",
            elementType: "geometry.fill",
            stylers: [{ color: "#575d63" }],
          },
          {
            featureType: "road.local",
            elementType: "geometry",
            stylers: [{ color: "#000000" }, { lightness: 16 }],
          },
          {
            featureType: "road.local",
            elementType: "geometry.fill",
            stylers: [{ color: "#575d63" }],
          },
          {
            featureType: "transit",
            elementType: "geometry",
            stylers: [{ color: "#000000" }, { lightness: 19 }],
          },
          {
            featureType: "transit",
            elementType: "geometry.fill",
            stylers: [{ color: "#2d353c" }],
          },
          {
            featureType: "water",
            elementType: "geometry",
            stylers: [{ color: "#000000" }, { lightness: 17 }],
          },
          {
            featureType: "water",
            elementType: "geometry.fill",
            stylers: [{ color: "#1a1f23" }],
          },
        ],
      },
      events: {
        data: eventsData,
        displayData: eventsData,
      },
    };
  },
  methods: {
    formatDate(d) {
      var monthsName = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      d = new Date(d);
      d = monthsName[d.getMonth()] + " " + d.getDate();
      return d;
    },
    handleDayChanged(data) {
      if (data.events.length > 0) {
        this.events.displayData = data.events;
      } else {
        this.events.displayData = this.events.data;
      }
    },
    handleMonthChanged() {},
  },
  components: {},
  mounted() {
    if (
      process.env.VUE_APP_ENVIRONMENT === "PRODUCTION" &&
      location.protocol !== "https:"
    ) {
      location.replace(
        `https:${location.href.substring(location.protocol.length)}`
      );
    }
    var persona = JSON.parse(localStorage.getItem("persona"));
    if (!persona) {
      this.$router.push("/Admin/Login");
    }
  },
};
