import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
  name: "MagnumPage",
  data() {
    let ss = new MainService();
    return {
      msg: "MagnumPage",
      ss: ss,
      ajax: {
        url: ss.indexMagnum(),
        headers: ss.getToken(),
      },
      columns: [
        {
          data: "id",
          name: "id",
          orderable: false,
          searchable: false,
          visible: false,
        },
        {
          data: "DT_RowIndex",
          name: "DT_RowIndex",
          title: "N",
          orderable: false,
          searchable: false,
        },
        { data: "Magnum", name: "Magnum", title: "Magnum" },
        { data: "RGB", name: "RGB", title: "RGB" },
        {
          data: "RGB",
          searchable: false,
          name: "RGB",
          title: "Magnum Color",
          render: function (data, type, row) {
            if (row.RGB) {
              return (
                '<div style="background-color:rgb(' +
                row.RGB +
                ');"> </br></br></div>'
              );
            } else {
              return '<div style="background-color:red;"> </br>ERROR COLOR</br></div>';
            }
          },
        },
        {
          data: "Activo",
          name: "Activo",
          title: "Estado",
          className: "centradito",
          render: function (data, type, row) {
            if (!row.Activo) {
              return '<i class="fa fa-ban text-danger"></i>';
            } else {
              return '<i class="fa fa-check text-success"></i>';
            }
          },
        },
        {
          data: "action",
          orderable: false,
          title: "Acciones",
          searchable: false,
        },
      ],
      magnums: [],
      magnum: {},
      isLoading: false,
      errorBag: {},
    };
  },
  methods: {
    newMagnum() {
      this.magnum = {};
      this.$refs["frm-magnum"].show();
    },
    showMagnum(id) {
      this.isLoading = true;
      this.ss
        .showMagnum(id)
        .then((result) => {
          let response = result.data;
          this.magnum = response.data;
          this.$refs["view-magnum"].show();
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    editMagnum() {
      this.$refs["frm-magnum"].show();
      this.$refs["view-magnum"].hide();
    },
    cancelMagnum() {
      if (this.magnum.id) {
        this.$refs["view-magnum"].show();
      }
      this.$refs["frm-magnum"].hide();
    },
    saveMagnum() {
      this.ss
        .storeMagnum(this.magnum)
        .then((result) => {
          console.log(result);
          let response = result.data;
          this.$bvToast.toast(response.msg, {
            title: this.$t("messages.success"),
            variant: "success",
            autoHideDelay: 5000,
          });
          //this.$refs['view-consulta'].show(); //para volver al modal
          console.log(response);
          this.$refs["frm-magnum"].hide();
          this.$refs["datatable-magnum"].reload();
        })
        .catch((error) => {
          this.errorBag = error.response.data.errors;
          this.$bvToast.toast(this.$t("messages.problemSave"), {
            title: "Error",
            variant: "danger",
            autoHideDelay: 5000,
          });
        });
    },
    deleteMagnum() {
      this.$swal({
        title: "Estas seguro que deseas eliminar?",
        text: "Esta accion es irreversible!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete.isConfirmed) {
          this.ss
            .destroyMagnum(this.magnum)
            .then((result) => {
              let response = result.data;
              console.log(response);
              this.$bvToast.toast(response.msg, {
                title: this.$t("messages.success"),
                variant: "success",
                autoHideDelay: 5000,
              });
              this.$refs["view-magnum"].hide();
              this.$refs["datatable-magnum"].reload();
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          //swal("Your imaginary file is safe!");
        }
      });
    },
    draw() {
      window.$(".btn-datatable-Magnum").on("click", (evt) => {
        const data = window.$(evt.target)[0].id;
        this.showMagnum(data);
      });
    },
  },
  components: {
    dataTable,
    Loader,
  },
  mounted() {
    var persona = JSON.parse(localStorage.getItem("persona"));
    if (!persona) {
      this.$router.push("/Admin/Login");
    } else {
      if (persona.Rol !== 1) {
        this.$router.push("/Admin/Login");
      }
    }
  },
};
