import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

Vue.prototype.$locale = {
  change(lang) {
    i18n.locale = lang;
    localStorage.setItem("lang", lang);
    console.log("i18n lang", lang);
    console.log("i18n lang storage", localStorage.getItem("lang"));
  },
  current() {
    return i18n.locale;
  },
};

const i18n = new VueI18n({
  locale: localStorage.getItem("lang") || navigator.language,
  silentFallbackWarn: true,
  fallbackLocale: localStorage.getItem("lang") || navigator.language,
  messages: loadLocaleMessages(),
});

export default i18n;
