import MainService from "@/services/MainService.js";
import PageOptions from "../../../config/PageOptions.vue";

export default {
  name: "RememberPasswordPage",
  data() {
    let ss = new MainService();
    return {
      msg: "RememberPasswordPage",
      ss: ss,
      ajax: {
        url: ss.indexPersona(),
        headers: ss.getToken(),
      },
      loginRequest: {},
    };
  },
  created() {
    PageOptions.pageEmpty = true;
    document.body.className = "bg-white";
  },
  beforeRouteLeave(to, from, next) {
    PageOptions.pageEmpty = false;
    document.body.className = "";
    next();
  },
  methods: {
    checkForm: function (e) {
      e.preventDefault();
      this.resetPassword();
    },
    isEmpty(data) {
      return !data || data === "";
    },
    resetPassword() {
      this.ss
        .resetPassword(this.loginRequest)
        .then((result) => {
          let response = result.data;
          this.$bvToast.toast(this.$t("messages.resetPasswordMessage"), {
            title: this.$t("messages.success"),
            variant: "success",
            autoHideDelay: 5000,
          });
        })
        .catch((error) => {
          this.errorBag = error.response.data.errors;
          this.$bvToast.toast(this.$t("messages.errorDefault"), {
            title: "Error",
            variant: "danger",
            autoHideDelay: 5000,
          });
        });
    },
  },
  mounted() {
    if (
      process.env.VUE_APP_ENVIRONMENT === "PRODUCTION" &&
      location.protocol !== "https:"
    ) {
      location.replace(
        `https:${location.href.substring(location.protocol.length)}`
      );
    }
  },
};
