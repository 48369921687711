<template>
  <div>
    <!-- begin #header -->
    <div id="header" class="header navbar-default">
      <!-- begin navbar-header -->
      <div class="navbar-header">
        <button
          type="button"
          class="navbar-toggle pull-left"
          v-on:click="toggleMobileRightSidebar"
          v-if="pageOptions.pageWithTwoSidebar"
        >
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>

        <v-app-bar-nav-icon
          v-if="$vuetify.breakpoint.smAndDown"
          @click.stop="drawer = !drawer"
        ></v-app-bar-nav-icon>

        <router-link v-if="this.admin === 'false'" to="/" class="navbar-brand"
          ><img
            height="32px"
            width="32px"
            style="margin-right: 8px; margin-left: 16px"
            src="/assets/img/solo_icono.png"
            alt=""
          />
          <b>Magnum&nbsp;</b> Mixing System</router-link
        >
        <router-link
          v-if="this.admin === 'true'"
          to="/Admin"
          class="navbar-brand"
          ><img
            height="32px"
            width="32px"
            style="margin-right: 8px; margin-left: 16px"
            src="/assets/img/solo_icono.png"
            alt=""
          />
          <b>Magnum&nbsp;</b> Mixing System</router-link
        >
        <button
          type="button"
          class="navbar-toggle pt-0 pb-0 mr-0 collapsed"
          v-on:click="toggleMobileTopMenu"
          v-if="pageOptions.pageWithTopMenu && !pageOptions.pageWithoutSidebar"
        >
          <span class="fa-stack fa-lg text-inverse">
            <i class="far fa-square fa-stack-2x"></i>
            <i class="fa fa-cog fa-stack-1x"></i>
          </span>
        </button>
        <button
          type="button"
          class="navbar-toggle"
          v-on:click="toggleMobileTopMenu"
          v-if="pageOptions.pageWithTopMenu && pageOptions.pageWithoutSidebar"
        >
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <button
          type="button"
          class="navbar-toggle"
          v-on:click="toggleMobileSidebar"
          v-if="!pageOptions.pageWithoutSidebar"
        >
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
      </div>
      <!-- end navbar-header -->

      <header-mega-menu v-if="pageOptions.pageWithMegaMenu"></header-mega-menu>

      <!-- begin header-nav -->
      <ul class="navbar-nav navbar-right">
        <label v-if="this.admin === 'false'" style="margin-top: 24px">{{
          $t("messages.language")
        }}</label>
        <div
          v-if="this.admin === 'false'"
          style="
            font-size: 24px;
            margin-top: 18px;
            margin-right: 16px;
            margin-left: 12px;
          "
        >
          <flag iso="es" />
        </div>
        <b-form-checkbox
          v-if="this.admin === 'false'"
          v-model="language"
          ref="toggle"
          name="check-button"
          switch
          @change="check"
          style="margin-top: 24px"
        />
        <div
          v-if="this.admin === 'false'"
          style="font-size: 24px; margin-top: 18px; margin-left: 8px"
        >
          <flag iso="us" />
        </div>
        <template v-if="this.admin === 'false'">
          <div style="margin-left: 8px; margin-top: 16px">
            <v-divider vertical />
          </div>
        </template>

        <b-nav-item-dropdown
          menu-class="dropdown-menu-right"
          class="dropdown navbar-user mt-2"
          no-caret
        >
          <template slot="button-content">
            <img :src="foto" alt="" />
            <span class="d-none d-md-inline"> {{ auth.Persona }} </span>
            <b class="caret"></b>
          </template>
          <b-dropdown-item @click.prevent="editPerfil">{{
            $t("actions.editProfile")
          }}</b-dropdown-item>
          <b-dropdown-item v-if="this.auth.Rol === 1" @click.prevent="goAdmin">
            Login Administrador
          </b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item @click.prevent="cerrarSesion">{{
            $t("actions.closeSession")
          }}</b-dropdown-item>
        </b-nav-item-dropdown>
        <li
          class="divider d-none d-md-block"
          v-if="pageOptions.pageWithTwoSidebar"
        ></li>
        <li class="d-none d-md-block" v-if="pageOptions.pageWithTwoSidebar">
          <a
            href="javascript:;"
            v-on:click="toggleRightSidebarCollapsed"
            class="f-s-14"
          >
            <i class="fa fa-th"></i>
          </a>
        </li>
      </ul>
      <!-- end header navigation right -->
    </div>

    <v-navigation-drawer v-model="drawer" absolute>
      <v-list nav tag="ul">
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <v-list-item tag="li"> </v-list-item>
          <v-list-item tag="li"> </v-list-item>
          <v-list-item tag="li">
            <a href="#" @click.prevent="colorMix">
              <i class="fa fa-fill-drip"></i> Color Mix
            </a>
          </v-list-item>

          <v-list-item tag="li">
            <a href="#" @click.prevent="misFormulas">
              <i class="fa fa-palette"></i> {{ $t("nav.MyFormulas") }}</a
            >
          </v-list-item>

          <v-list-item tag="li">
            <a href="#" @click.prevent="misSugerencias">
              <i class="fa fa-message mr-1 mr-md-0"></i>
              {{ $t("nav.MySuggestions") }}</a
            >
          </v-list-item>

          <v-list-item tag="li">
            <a href="#" @click.prevent="misNotificaciones">
              <i class="fa fa-bell"></i> {{ $t("nav.Notifications") }}
            </a>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <!-- end #header -->
  </div>
</template>

<script>
import PageOptions from "../../config/PageOptions.vue";
import HeaderMegaMenu from "./HeaderMegaMenu.vue";
import MainService from "@/services/MainService.js";
import StorageMethods from "@/utils/StorageMethods.js";

export default {
  name: "Header",
  components: {
    HeaderMegaMenu,
  },
  data() {
    let ss = new MainService();
    let storage = new StorageMethods();
    return {
      pageOptions: PageOptions,
      auth: {},
      ss: ss,
      storage: storage,
      admin: "false",
      language: false,
      drawer: false,
      group: null,
      foto: null,
    };
  },

  watch: {
    group() {
      this.drawer = false;
    },
  },

  methods: {
    cerrarSesion() {
      let admin = localStorage.getItem("admin");
      this.storage.cleanSession();
      if (admin === "true") {
        this.$router.push("/Admin/Login");
      } else {
        this.$router.push("/Login");
      }
    },
    inputFocus() {
      this.$refs.toggle.focus();
    },
    misFormulas() {
      this.$router.push("/MisFormulas");
    },
    colorMix() {
      this.$router.push("/");
    },
    misSugerencias() {
      this.$router.push("/MisSugerencias");
    },
    misNotificaciones() {
      this.$router.push("/Notificaciones");
    },
    getVersionTest() {
      this.ss
        .getVersion()
        .then((result) => {
          let response = result.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    check() {
      this.$swal({
        title: this.$t("messages.changeLanguage"),
        text: this.$t("messages.messageChangeLanguage"),
        icon: "warning",
        buttons: true,
        cancelButtonColor: "#DD6B55",
        cancelButtonText: this.$t("messages.cancelInvert"),
        showCancelButton: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete.isConfirmed) {
          let locale = navigator.language;
          this.language ? (locale = "en-US") : (locale = "es-ES");
          this.$locale.change(locale);
          const route = Object.assign({}, this.$route);
          route.params.lang = locale;
          this.$router.push(`/Login?lang=${locale}`);
        } else {
          this.language = !this.language;
        }
      });
    },
    editPerfil() {
      if (this.admin === "true") {
        this.$router.push("/Admin/Profile");
      } else {
        this.$router.push("/Profile");
      }
    },

    goAdmin() {
      this.$router.push("/Admin/Login");
    },

    notificaciones() {
      this.$router.push("/Notificaciones");
    },

    toggleMobileSidebar() {
      this.pageOptions.pageMobileSidebarToggled =
        !this.pageOptions.pageMobileSidebarToggled;
    },
    toggleMobileRightSidebar() {
      this.pageOptions.pageMobileRightSidebarToggled =
        !this.pageOptions.pageMobileRightSidebarToggled;
    },
    toggleMobileTopMenu() {
      this.pageOptions.pageMobileTopMenu = !this.pageOptions.pageMobileTopMenu;
    },
    toggleMobileMegaMenu() {
      this.pageOptions.pageMobileMegaMenu =
        !this.pageOptions.pageMobileMegaMenu;
    },
    toggleRightSidebar() {
      this.pageOptions.pageRightSidebarToggled =
        !this.pageOptions.pageRightSidebarToggled;
    },
    toggleRightSidebarCollapsed() {
      this.pageOptions.pageRightSidebarCollapsed =
        !this.pageOptions.pageRightSidebarCollapsed;
    },
    checkForm: function (e) {
      e.preventDefault();
      this.$router.push({ path: "/extra/search" });
    },
  },
  mounted() {
    this.auth = JSON.parse(localStorage.getItem("persona"));
    if (this.auth.Foto) {
      this.foto = this.auth.Foto;
    } else {
      this.foto = this.auth.URLFoto;
    }
    this.admin = localStorage.getItem("admin");
    this.language = this.$root.$i18n.locale.includes("en");
  },
};
</script>
