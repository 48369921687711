import MainService from "@/services/MainService.js";
import Vue from "vue";
import VueAxios from "vue-axios";
import axios from "axios";
import StorageMethods from "@/utils/StorageMethods.js";
import { clearCache } from "clear-cache";

import PageOptions from "../../../config/PageOptions.vue";

Vue.use(VueAxios, axios);
// Vue.use( axios);

export default {
  name: "LoginPage",
  data() {
    let storage = new StorageMethods();
    let ss = new MainService();
    return {
      ss: ss,
      loginRequest: {},
      errorBag: {},
      hash: "",
      storage: storage,
      size: 300,
      alias: "",
      channel: "",
      name: process.env.VUE_APP_TITLE,
      unlock: false,
      bg: {
        activeImg: "/assets/img/login-bg/login-bg-17.jpg",
        bg1: {
          active: true,
          img: "/assets/img/login-bg/login-bg-17.jpg",
        },
        bg2: {
          active: false,
          img: "/assets/img/login-bg/login-bg-16.jpg",
        },
        bg3: {
          active: false,
          img: "/assets/img/login-bg/login-bg-15.jpg",
        },
        bg4: {
          active: false,
          img: "/assets/img/login-bg/login-bg-14.jpg",
        },
        bg5: {
          active: false,
          img: "/assets/img/login-bg/login-bg-13.jpg",
        },
        bg6: {
          active: false,
          img: "/assets/img/login-bg/login-bg-12.jpg",
        },
      },
    };
  },
  components: {
    // QrcodeVue,
  },
  props: {
    msg: String,
  },

  created() {
    PageOptions.pageEmpty = true;
  },
  beforeRouteLeave(to, from, next) {
    PageOptions.pageEmpty = false;
    PageOptions.pageWithFooter = true;
    next();
  },

  methods: {
    unlockPassword() {
      this.unlock = !this.unlock;
      var x = document.getElementById("passwordLoginAdmin");
      if (x.type === "password") {
        x.type = "text";
      } else {
        x.type = "password";
      }
    },
    getVersion() {
      this.ss
        .getVersion()
        .then((result) => {
          let response = result.data;
          var pjson = require("../../../../package.json");
          var local = response.version;
          if (pjson.version !== local) {
            clearCache(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    login() {
      this.ss
        .login(this.loginRequest)
        .then((result) => {
          console.log(result);
          if (result.data.data.Rol === 1) {
            localStorage.setItem("persona", JSON.stringify(result.data.data));
            localStorage.setItem("token", JSON.stringify(result.data.token));
            localStorage.setItem("admin", true);
            this.$router.push("/Admin/");
            this.$router.go();
          } else {
            this.$bvToast.toast(
              "Problema al Iniciar Sesion, es probable que no sea un administrador",
              {
                title: "Error",
                variant: "warning",
                autoHideDelay: 5000,
              }
            );
          }

          /*var persona = JSON.parse(localStorage.getItem('persona'));
                                            console.log(persona)*/
        })
        .catch((error) => {
          //this.errorBag = error.response.data.errors;
          this.$bvToast.toast(
            "Problema al Iniciar Sesion, usuario inexistente o credenciales incorrectas",
            {
              title: "Error",
              variant: "danger",
              autoHideDelay: 5000,
            }
          );
        });
    },
    // showCodigoQR() {
    //     this.$refs["view-codigoqr"].show();
    // },
    // cancelCodigoQR() {
    //     this.$refs["view-codigoqr"].hide();
    // },

    // pagina

    checkForm: function (e) {
      e.preventDefault();
      this.$router.push({ path: "/dashboard/v2" });
    },
    select: function (x) {
      this.bg.bg1.active = false;
      this.bg.bg2.active = false;
      this.bg.bg3.active = false;
      this.bg.bg4.active = false;
      this.bg.bg5.active = false;
      this.bg.bg6.active = false;

      switch (x) {
        case "bg1":
          this.bg.bg1.active = true;
          this.bg.activeImg = this.bg.bg1.img;
          break;
        case "bg2":
          this.bg.bg2.active = true;
          this.bg.activeImg = this.bg.bg2.img;
          break;
        case "bg3":
          this.bg.bg3.active = true;
          this.bg.activeImg = this.bg.bg3.img;
          break;
        case "bg4":
          this.bg.bg4.active = true;
          this.bg.activeImg = this.bg.bg4.img;
          break;
        case "bg5":
          this.bg.bg5.active = true;
          this.bg.activeImg = this.bg.bg5.img;
          break;
        case "bg6":
          this.bg.bg6.active = true;
          this.bg.activeImg = this.bg.bg6.img;
          break;
      }
    },
  },
  mounted() {
    if (
      process.env.VUE_APP_ENVIRONMENT === "PRODUCTION" &&
      location.protocol !== "https:"
    ) {
      location.replace(
        `https:${location.href.substring(location.protocol.length)}`
      );
    }
    this.getVersion();
    var persona = JSON.parse(localStorage.getItem("persona"));
    if (persona) {
      this.storage.cleanSession();
    }
  },
};
